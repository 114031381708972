import {
    APP_FETCH_DATA,
    APP_DONE,
    APP_ERROR,
    APP_ERROR_CLEAR,
    APP_PENDING,
    APP_VALID_NAME,
    APP_INVALID_NAME,
    APP_IS_OWN_DOMAIN,
    APP_IS_NOT_OWN_DOMAIN,
    APP_CLEAR_VALIDATION,
    APP_ERROR_SUBMIT,
    APP_NAME_ALREADY_EXISTS,
    APP_DOMAIN_NOT_FOUND,
    APP_PENDING_FETCH_DATA,
    APP_PENDING_DONE,
    APP_PENDING_ERROR,
    APP_PENDING_PENDING,
    APP_DELEGATED_FETCH_DATA,
    APP_DELEGATED_DONE,
    APP_DELEGATED_PENDING,
    APP_DELEGATED_ERROR,
    APP_FETCH_LOGS,
    APP_FETCH_LOGS_DONE,
    APP_FETCH_LOGS_PENDING,
    APP_FETCH_LOGS_ERROR,
    APP_CLEAR_STATE_DATA,
    APP_BY_ID_PENDING,
    APP_BY_ID_DONE,
    APP_BY_ID_ERROR,
    APP_BY_ID_FETCH_DATA,
    APP_BY_ID_CLEAR,
    APP_APP_CLEAR_STATE_DATA,
    APP_DELEGATED_CLEAR_STATE_DATA
} from "../actions/applicationsActions";

const initialState = {
    app: {
        done: true,
        data: {
            applications: [],
            totalItems: 0
        },
        error: null,
        isNameValidated: false,
        isNameInvalid: false,
        isNameAlreadyExists: false,
        applicationOwner: null,
        isDomainNotFound: false,
        isOwnDomain: false,
        submitErrorMessage: null
    },
    appPending: {
        done: false,
        data: {
            applications: [],
            totalItems: 0
        },
        error: null
    },
    appDelegated: {
        done: false,
        data: {
            applications: [],
            totalItems: 0
        },
        error: null
    },
    appById: {
        done: true,
        data: [],
        error: null
    },
    logs: {
        done: false,
        data: [],
        error: null
    }
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_FETCH_DATA:
            return {
                ...state,
                app: {
                    ...state.app,
                    data: {applications: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case APP_DONE:
            return {...state, app: {...state.app, done: true, error: null}};
        case APP_PENDING:
            return {...state, app: {...state.app, done: false, error: null}};
        case APP_ERROR:
            return {...state, app: {...state.app, done: true, error: action.payload}};
        case APP_ERROR_CLEAR:
            return {...state, app: {...state.app, error: null}};
        case APP_VALID_NAME:
            return {...state, app: {...state.app, isNameValidated: true, isNameInvalid: false}};
        case APP_INVALID_NAME:
            return {...state, app: {...state.app, isNameValidated: true, isNameInvalid: true}};
        case APP_NAME_ALREADY_EXISTS:
            return {...state, app: {...state.app, isNameValidated: true, isNameAlreadyExists: true, applicationOwner: action.payload}};
        case APP_DOMAIN_NOT_FOUND:
            return {...state, app: {...state.app, isNameValidated: true, isDomainNotFound: true}};
        case APP_IS_OWN_DOMAIN:
            return {...state, app: {...state.app, isOwnDomain: true}};
        case APP_IS_NOT_OWN_DOMAIN:
            return {...state, app: {...state.app, isOwnDomain: false}};
        case APP_CLEAR_VALIDATION:
            return {
                ...state,
                app: {
                    ...state.app,
                    isNameValidated: false,
                    isNameInvalid: false,
                    isNameAlreadyExists: false,
                    isDomainNotFound: false,
                    isOwnDomain: false,
                    applicationOwner: null
                }
            };
        case APP_ERROR_SUBMIT:
            return {...state, app: {...state.app, submitErrorMessage: action.payload}};
        case APP_PENDING_FETCH_DATA:
            return {
                ...state,
                appPending: {
                    ...state.appPending,
                    data: {applications: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case APP_PENDING_DONE:
            return {...state, appPending: {...state.appPending, done: true, error: null}};
        case APP_PENDING_PENDING:
            return {...state, appPending: {...state.appPending, done: false, error: null}};
        case APP_PENDING_ERROR:
            return {...state, appPending: {...state.appPending, done: true, error: action.payload}};
        case APP_DELEGATED_FETCH_DATA:
            return {
                ...state,
                appDelegated: {
                    ...state.appDelegated,
                    data: {applications: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case APP_DELEGATED_DONE:
            return {...state, appDelegated: {...state.appDelegated, done: true, error: null}};
        case APP_DELEGATED_PENDING:
            return {...state, appDelegated: {...state.appDelegated, done: false, error: null}};
        case APP_DELEGATED_ERROR:
            return {...state, appDelegated: {...state.appDelegated, done: true, error: action.payload}};
        case APP_FETCH_LOGS:
            return {...state, logs: {...state.logs, data: action.payload.response}};
        case APP_FETCH_LOGS_DONE:
            return {...state, logs: {...state.logs, done: true, error: null}};
        case APP_FETCH_LOGS_ERROR:
            return {...state, logs: {...state.logs, done: true, error: action.payload}};
        case APP_FETCH_LOGS_PENDING:
            return {...state, logs: {...state.logs, done: false, error: null}};
        case APP_BY_ID_FETCH_DATA:
            return {...state, appById: {...state.appById, data: action.payload.response}};
        case APP_BY_ID_DONE:
            return {...state, appById: {...state.appById, done: true, error: null}};
        case APP_BY_ID_ERROR:
            return {...state, appById: {...state.appById, done: true, error: action.payload}};
        case APP_BY_ID_PENDING:
            return {...state, appById: {...state.appById, done: false, error: null}};
        case APP_BY_ID_CLEAR:
            return {...state, appById: initialState.appById};
        case APP_APP_CLEAR_STATE_DATA:
            return {...state, app: initialState.app};
        case APP_DELEGATED_CLEAR_STATE_DATA:
            return {...state, appDelegated: initialState.appDelegated};
        case APP_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default appReducer;