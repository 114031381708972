import React from "react";
import PropTypes from "prop-types";

/**
 * A standard column looks like this:
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/grid" target="_blank">Col</a>
 *
 * @example ../../../styleguide/grid/Col.md
 */
const Col = props => {
    const { lg, md, sm, xs, className, children } = props;

    return (
        <div className={`vl-col--${lg}-12${md ? ` vl-col--${md}-12--m` : ''}${sm ? ` vl-col--${sm}-12--s` : ''}${xs ? ` vl-col--${xs}-12--xs` : ''}${className ? ` ${className}` : ''}`}>
            {children}
        </div>
    );
};

Col.propTypes = {
    /**
     * [Optional] large screens size.
     */
    lg: PropTypes.string,
    /**
     * [Optional] medium screens size.
     */
    md: PropTypes.string,
    /**
     * [Optional] small screens size.
     */
    sm: PropTypes.string,
    /**
     * [Optional] extra-small screens size.
     */
    xs: PropTypes.string,
    /**
     * [Optional] adds a additional classes to component.
     */
    className: PropTypes.string,
    /**
     * [Required] display the content of component.
     */
    children: PropTypes.node.isRequired
};

Col.defaultProps = {
    lg: "12",
    md: null,
    sm: null,
    xs: null,
    className: null,
    children: "Col"
};

export default Col;