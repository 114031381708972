import axios from "axios";
import {getUserInformation, handleErrors} from "./commonActions";

export const WHITELIST_FETCH_DATA = 'WHITELIST_FETCH_DATA';
export const WHITELIST_PENDING = 'WHITELIST_PENDING';
export const WHITELIST_DONE = 'WHITELIST_DONE';
export const WHITELIST_ERROR = 'WHITELIST_ERROR';
export const WHITELIST_ERROR_CLEAR = 'WHITELIST_ERROR_CLEAR';
export const WHITELIST_CLEAR_STATE_DATA = 'WHITELIST_CLEAR_STATE_DATA';

let apiURL = process.env.REACT_APP_API_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

/**
 * fetch all the whitelist origin data
 *
 * @param {object} pagination receive a object with the pagination configurations
 */
export const fetchWhitelistOrigins = (pagination = initialPagination) => async dispatch => {
    dispatch({type: WHITELIST_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const query = {
            organization_id: orgId,
            queryParameters: {
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                orderBy: "id",
                order: "DESC"
            },
            returnValues: ["id", "origin", "description", "last_updated", "modified_by"]
        }

        const response = await axios.post(apiURL + '/whitelist-origin/search', query, {headers: {Authorization: authorizationToken}});
        if (response) {
            await Promise.all(response.data.response.map(async whitelist => {
                const userInfoResponse = await dispatch(getUserInformation(orgId, whitelist.modified_by));
                whitelist.modified_by = userInfoResponse.name ? `${userInfoResponse.name} ${userInfoResponse.last_name}` : null;

                return whitelist;
            }));
            dispatch({type: WHITELIST_FETCH_DATA, payload: response.data});
        }
        dispatch({type: WHITELIST_DONE});
    } catch (err) {
        dispatch(handleErrors(err, WHITELIST_ERROR));
    }
};

/**
 * make a call to API to add a new whitelist origin
 *
 * @param {object} values object with all the necessary input values to create a new whitelist origin
 */
export const addNewWhitelistOrigin = values => async dispatch => {
    dispatch({type: WHITELIST_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/whitelist-origin/add', {
            organization_id: orgId,
            data: {
                origin: values.origin,
                description: values.description
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, WHITELIST_ERROR));
    }
};

/**
 * update the selected whitelist origin
 *
 * @param {any} values values necessary to update the whitelist origin.
 */
export const updateWhitelistOrigin = values => async dispatch => {
    dispatch({type: WHITELIST_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/whitelist-origin/update', {
            organization_id: orgId,
            data: {
                id: values.id,
                origin: values.origin,
                description: values.description
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, WHITELIST_ERROR));
    }
};

/**
 * deletes the selected whitelist origin from the list
 *
 * @param {number} id id of the whitelist origin to be deleted.
 */
export const deleteWhitelistOrigin = id => async dispatch => {
    dispatch({type: WHITELIST_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/whitelist-origin/delete', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, WHITELIST_ERROR));
    }
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: WHITELIST_CLEAR_STATE_DATA});
};