import axios from "axios";
import {getUserInformation, handleErrors} from "./commonActions";
import {isEmpty, uniqBy} from "lodash";

export const VAL_RULES_FETCH_DATA = 'VAL_RULES_FETCH_DATA';
export const VAL_RULES_PENDING = 'VAL_RULES_PENDING';
export const VAL_RULES_DONE = 'VAL_RULES_DONE';
export const VAL_RULES_ERROR = 'VAL_RULES_ERROR';
export const VAL_RULES_ERROR_CLEAR = 'VAL_RULES_ERROR_CLEAR';
export const VAL_RULE_BY_ID_FETCH_DATA = 'VAL_RULE_BY_ID_FETCH_DATA';
export const VAL_RULE_BY_ID_PENDING = 'VAL_RULE_BY_ID_PENDING';
export const VAL_RULE_BY_ID_DONE = 'VAL_RULE_BY_ID_DONE';
export const VAL_RULE_BY_ID_ERROR = 'VAL_RULE_BY_ID_ERROR';
export const VAL_RULE_BY_ID_CLEAR = 'VAL_RULE_BY_ID_CLEAR';
export const VAL_RULES_CLEAR_STATE_DATA = 'VAL_RULES_CLEAR_STATE_DATA';

let apiURL = process.env.REACT_APP_API_URL, apiUrlES = process.env.REACT_APP_API_ES_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

//initial values for filtering table results
const initialFilters = {
    requestor: [],
    targetGroup: []
}

/**
 * fetch all the validation rules data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 * @param {boolean} withModifiedBy flag if want to fetch modified by user info
 * @param {boolean} withEnabled flag if want to fetch with is_enabled = true
 */
export const fetchValidationRules = (pagination = initialPagination, filters = initialFilters, withModifiedBy = true, withEnabled = false) => async dispatch => {
    dispatch({type: VAL_RULES_PENDING});
    try {
        let orgIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const query = {
            organization_id: orgId,
            queryParameters: {
                id: filters.searchString ? filters.valRuleIds : [],
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                orderBy: "id",
                order: "DESC",
                requestor: filters.requestor,
                target_group: filters.targetGroup,
                is_enabled: withEnabled
            },
            returnValues: ["id", "description", "rule", "requestor", "target_group", "additional_desc", "last_updated", "modified_by", "is_enabled"]
        }

        const response = await axios.post(apiURL + '/validation-rule/search', query, {headers: {Authorization: authorizationToken}});
        if (response) {
            await Promise.all(response.data.response.map(valRule => {
                valRule.requestor.map(requestor => parseInt(requestor) > 0 && orgIds.push(parseInt(requestor)));
                valRule.target_group.map(target_group => parseInt(target_group) > 0 && orgIds.push(parseInt(target_group)));
                return valRule;
            }));
            //remove duplicate values
            orgIds = [...new Set(orgIds)];
            //fetch organizations by the ID's extracted previously and associate each correct name
            const orgResponse = await axios.post(apiURL + '/organization/search', {
                queryParameters: {
                    id: orgIds
                },
                returnValues: ["id", "name"]
            }, {headers: {Authorization: authorizationToken}});
            if (orgResponse) {
                //0 -> all organizations
                //-1 -> same as requestor
                const orgs = orgResponse.data.response;
                await Promise.all(response.data.response.map(async valRule => {
                        valRule.requestor_names = [];
                        valRule.target_group_names = [];
                        if (parseInt(valRule.requestor[0]) === 0) {
                            valRule.requestor_names.push({name: "*"});
                        } else {
                            await Promise.all(valRule.requestor.map(requestor => {
                                    orgs.map(org => {
                                        if (parseInt(requestor) === org.id) {
                                            valRule.requestor_names.push(org);
                                        }
                                        return org;
                                    });
                                    return requestor;
                                }
                            ));
                        }
                        if (parseInt(valRule.target_group[0]) === 0) {
                            valRule.target_group_names.push({name: "*"});
                        } else {
                            await Promise.all(valRule.target_group.map(targetGroup => {
                                    orgs.map(org => {
                                        if (parseInt(targetGroup) === -1) {
                                            valRule.target_group_names.push({name: "-1"});
                                        } else if (parseInt(targetGroup) === org.id) {
                                            valRule.target_group_names.push(org);
                                        }
                                        return org;
                                    })
                                    return targetGroup;
                                }
                            ));
                            //remove duplicate values
                            valRule.target_group_names = uniqBy(valRule.target_group_names, 'id');
                        }

                        if (valRule.modified_by && withModifiedBy) {
                            const userInfoResponse = await dispatch(getUserInformation(orgId, valRule.modified_by));
                            valRule.modified_by = userInfoResponse.name ? `${userInfoResponse.name} ${userInfoResponse.last_name}` : null;
                        }

                        return valRule;
                    }
                ));
            }
            dispatch({type: VAL_RULES_FETCH_DATA, payload: response.data});
        }
        dispatch({type: VAL_RULES_DONE});
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULES_ERROR));
    }
};

/**
 * fetch all the filtered validation rules data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 * @param {boolean} myValRulesFilter flag if is a "my validation rules" page filter or not
 */
export const filterFetchValidationRules = (pagination = initialPagination, filters = initialFilters, myValRulesFilter = false) => async dispatch => {
    dispatch({type: VAL_RULES_PENDING});
    try {
        let filtersCopy = filters, valRuleIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');

        if (myValRulesFilter) {
            const orgId = parseInt(localStorage.getItem('organizationId'));
            const query = {
                organization_id: orgId,
                queryParameters: {
                    requestor: filters.requestor,
                    target_group: filters.targetGroup,
                    is_enabled: true
                },
                returnValues: ["id"]
            }

            const valRuleResponse = await axios.post(apiURL + '/validation-rule/search', query, {headers: {Authorization: authorizationToken}});
            if (valRuleResponse) {
                await Promise.all(valRuleResponse.data.response.map(valRule => valRuleIds.push(valRule.id)));
            }
        }

        const valRuleESResponse = await axios.post(apiUrlES + '/essearch/validation-rule', {
            searchstring: filters.searchString,
            id: myValRulesFilter ? valRuleIds : [],
            attributes: [
                "description",
                "rule",
                "requestor_names",
                "target_group_names",
                "additional_desc"
            ]
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(valRuleESResponse?.data)) {
            valRuleIds = [];
            await Promise.all(valRuleESResponse.data.map(app => valRuleIds.push(parseInt(app._source.id))));
            filtersCopy.valRuleIds = valRuleIds;
            await dispatch(fetchValidationRules(pagination, filters, !myValRulesFilter, myValRulesFilter));
        } else {
            dispatch({type: VAL_RULES_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: VAL_RULES_DONE});
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULES_ERROR));
    }
};

/**
 * fetch the selected certificate data
 *
 * @param {number} id the id of validation rule to fetch
 */
export const fetchValidationRuleById = id => async dispatch => {
    dispatch({type: VAL_RULE_BY_ID_CLEAR});
    dispatch({type: VAL_RULE_BY_ID_PENDING});
    try {
        let orgIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const query = {
            organization_id: orgId,
            queryParameters: {
                id: [id]
            },
            returnValues: ["id", "description", "rule", "requestor", "target_group", "additional_desc", "last_updated", "modified_by", "is_enabled"]
        }

        const response = await axios.post(apiURL + '/validation-rule/search', query, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(response?.data.response)) {
            let data = response.data.response[0];
            data.requestor.map(requestor => parseInt(requestor) > 0 && orgIds.push(parseInt(requestor)));
            data.target_group.map(target_group => parseInt(target_group) > 0 && orgIds.push(parseInt(target_group)));
            //remove duplicate values
            orgIds = [...new Set(orgIds)];
            //fetch organizations by the ID's extracted previously and associate each correct name
            const orgResponse = await axios.post(apiURL + '/organization/search', {
                queryParameters: {
                    id: orgIds
                },
                returnValues: ["id", "name"]
            }, {headers: {Authorization: authorizationToken}});
            if (orgResponse) {
                const orgs = orgResponse.data.response;
                data.requestor_names = [];
                data.target_group_names = [];
                if (parseInt(data.requestor[0]) === 0) {
                    data.requestor_names.push({name: "*"});
                } else {
                    await Promise.all(data.requestor.map(requestor => {
                            orgs.map(org => {
                                if (parseInt(requestor) === org.id) {
                                    data.requestor_names.push(org);
                                }
                                return org;
                            });
                            return requestor;
                        }
                    ));
                }
                if (parseInt(data.target_group[0]) === 0) {
                    data.target_group_names.push({name: "*"});
                } else {
                    await Promise.all(data.target_group.map(targetGroup => {
                            orgs.map(org => {
                                if (parseInt(targetGroup) === 0) {
                                    data.target_group_names.push({name: "*"});
                                } else if (parseInt(targetGroup) === org.id) {
                                    data.target_group_names.push(org);
                                }
                                return org;
                            })
                            return targetGroup;
                        }
                    ));
                }

                if (data.modified_by) {
                    const userInfoResponse = await dispatch(getUserInformation(orgId, data.modified_by));
                    data.modified_by = userInfoResponse.name ? `${userInfoResponse.name} ${userInfoResponse.last_name}` : null;
                }
            }
            dispatch({type: VAL_RULE_BY_ID_FETCH_DATA, payload: {response: [data]}});
        }
        dispatch({type: VAL_RULE_BY_ID_DONE});
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULE_BY_ID_ERROR));
    }
};

/**
 * make a call to API to add a new validation rule
 *
 * @param {object} values object with all the necessary input values to create a new validation rule
 */
export const addNewValidationRule = values => async dispatch => {
    dispatch({type: VAL_RULES_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/validation-rule/add', {
            organization_id: orgId,
            data: {
                description: values.description,
                rule: values.rule,
                requestor: values.requestor,
                target_group: values.target_group,
                additional_desc: values.additional_desc
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULES_ERROR));
    }
};

/**
 * update the selected validation rule
 *
 * @param {any} values values necessary to update the validation rule.
 */
export const updateValidationRule = values => async dispatch => {
    dispatch({type: VAL_RULES_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/validation-rule/update', {
            organization_id: orgId,
            data: {
                id: values.id,
                description: values.description,
                rule: values.rule,
                requestor: values.requestor,
                target_group: values.target_group,
                additional_desc: values.additional_desc
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULES_ERROR));
    }
};

/**
 * updates the validation rule active status
 *
 * @param {object} valRuleId the id of validation rule to update
 * @param {object} status value necessary to update the validation rule
 */
export const updateValidationRuleStatus = (valRuleId, status) => async dispatch => {
    dispatch({type: VAL_RULES_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/validation-rule/update', {
            organization_id: orgId,
            data: {
                id: valRuleId,
                is_enabled: status,
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULES_ERROR));
    }
};

/**
 * deletes the selected validation rule from the list
 *
 * @param {number} id id of the validation rule to be deleted.
 */
export const deleteValidationRule = id => async dispatch => {
    dispatch({type: VAL_RULES_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/validation-rule/delete', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, VAL_RULES_ERROR));
    }
};

/**
 * clear the valRuleById data
 */
export const clearValRuleByIdData = () => async dispatch => {
    dispatch({type: VAL_RULE_BY_ID_CLEAR});
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: VAL_RULES_CLEAR_STATE_DATA});
};