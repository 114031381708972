import React from "react";
import PropTypes from "prop-types";

/**
 * Use the badge component to display an image, an icon or initials.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/atoms/vl-ui-badge" target="_blank">Badge</a>
 *
 * @example ../../styleguide/atoms/Badge.md
 */
const Badge = props => {
    const {type, size, color, isBlock, hasBorder, className} = props;

    return (
        <div className={`vl-badge${hasBorder ? ' vl-badge--border' : ''}${type === 'icon' ? ' vl-badge--icon' : ' vl-badge--initials'}${size ? ' vl-badge--' + size : ''}${color ? ` vl-badge--${color}` : ''}${isBlock ? ' vl-badge--block' : ''}${className ? ` ${className}` : ''}`}>
            {props.children}
        </div>
    );
};

Badge.propTypes = {
    /**
     * [Optional] type of badge.
     */
    type: PropTypes.oneOf(["icon", "initials"]),
    /**
     * [Optional] size of badge.
     */
    size: PropTypes.oneOf([
        "xxsmall", "xsmall", "small",
        "medium",
        "large", "xlarge",
    ]),
    /**
     * [Optional] color of badge. Default color its white.
     */
    color: PropTypes.oneOf([
        "alt", "action", "accent",
        "success", "warning", "error",
    ]),
    /**
     * [Optional] displayed as a block element.
     */
    isBlock: PropTypes.bool,
    /**
     * [Optional] add a border around badge.
     */
    hasBorder: PropTypes.bool,
    /**
     * [Optional] adds a additional classes to component.
     */
    className: PropTypes.string,
};

Badge.defaultProps = {
    type: "initials",
    size: null,
    color: null,
    isBlock: false,
    hasBorder: false,
    className: ""
};

export default Badge;