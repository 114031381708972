import {
    PARAM_PENDING,
    PARAM_DONE,
    PARAM_FETCH_DATA,
    PARAM_ERROR,
    PARAM_ERROR_CLEAR,
    PARAM_CLEAR_STATE_DATA,
    PARAM_SYSTEM_PARAMETERS_FETCH_DATA,
    PARAM_SYSTEM_PARAMETERS_ERROR,
    PARAM_SYSTEM_PARAMETERS_DONE,
    PARAM_SYSTEM_PARAMETERS_PENDING
} from "../actions/parameterActions";

const initialState = {
    param: {
        done: true,
        data: {
            parameters: [],
            totalItems: 0
        },
        error: null,
    },
    systemParam: {
        done: true,
        data: [],
        error: null,
    }
};

const paramReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARAM_FETCH_DATA:
            return {
                ...state,
                param: {
                    ...state.param,
                    data: {parameters: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case PARAM_DONE:
            return {...state, param: {...state.param, done: true, error: null}};
        case PARAM_PENDING:
            return {...state, param: {...state.param, done: false, error: null}};
        case PARAM_ERROR:
            return {...state, param: {...state.param, done: true, error: action.payload}};
        case PARAM_ERROR_CLEAR:
            return {...state, param: {...state.param, error: null}};
        case PARAM_CLEAR_STATE_DATA:
            return {...state, param: initialState.param};
        case PARAM_SYSTEM_PARAMETERS_FETCH_DATA:
            return {
                ...state,
                systemParam: {
                    ...state.systemParam,
                    data: action.payload.response
                }
            };
        case PARAM_SYSTEM_PARAMETERS_DONE:
            return {...state, systemParam: {...state.systemParam, done: true, error: null}};
        case PARAM_SYSTEM_PARAMETERS_PENDING:
            return {...state, systemParam: {...state.systemParam, done: false, error: null}};
        case PARAM_SYSTEM_PARAMETERS_ERROR:
            return {...state, systemParam: {...state.systemParam, done: true, error: action.payload}};
        default:
            return state;
    }
};

export default paramReducer;