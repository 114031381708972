import {
    CER_FETCH_DATA,
    CER_DONE,
    CER_ERROR,
    CER_ERROR_CLEAR,
    CER_PENDING,
    CER_CER_CLEAR_STATE_DATA,
    CER_DELEGATED_FETCH_DATA,
    CER_DELEGATED_DONE,
    CER_DELEGATED_ERROR,
    CER_DELEGATED_ERROR_CLEAR,
    CER_DELEGATED_PENDING,
    CER_DELEGATED_CLEAR_STATE_DATA,
    CER_BY_ID_FETCH_DATA,
    CER_BY_ID_DONE,
    CER_BY_ID_PENDING,
    CER_BY_ID_ERROR,
    CER_BY_ID_CLEAR,
    CER_FETCH_CERT_TYPES,
    CER_FETCH_CERT_TYPES_DONE,
    CER_FETCH_CERT_TYPES_ERROR,
    CER_FETCH_CERT_TYPES_PENDING,
    CER_CSR_VALIDATE,
    CER_CSR_VALIDATE_DONE,
    CER_CSR_VALIDATE_PENDING,
    CER_CSR_VALIDATE_ERROR,
    CER_CSR_INVALID_CERTIFICATE,
    CER_CSR_CLEAR_VALIDATION,
    CER_EXPORT_PENDING,
    CER_EXPORT_DONE,
    CER_EXPORT_ERROR,
    CER_FETCH_LOGS,
    CER_FETCH_LOGS_DONE,
    CER_FETCH_LOGS_PENDING,
    CER_FETCH_LOGS_ERROR,
    CER_CLEAR_STATE_DATA,
    CER_IMPORT_DONE,
    CER_IMPORT_ERROR,
    CER_IMPORT_PENDING,
    CER_IMPORTED_FETCH_DATA,
    CER_IMPORTED_PENDING,
    CER_IMPORTED_DONE,
    CER_IMPORTED_ERROR,
    CER_IMPORTED_CLEAR_STATE_DATA,
    CER_CSR_DETAILS_FETCH_DATA,
    CER_CSR_DETAILS_PENDING,
    CER_CSR_DETAILS_ERROR,
    CER_CSR_DETAILS_DONE
} from "../actions/certificatesActions";

const initialState = {
    cer: {
        done: true,
        data: {
            certificates: [],
            totalItems: 0
        },
        error: null,
    },
    cerImported: {
        done: true,
        data: {
            certificates: [],
            totalItems: 0
        },
        error: null,
    },
    cerDelegated: {
        done: true,
        data: {
            certificates: [],
            totalItems: 0
        }
    },
    cerTypes: {
        data: [],
        done: false,
        error: null,
    },
    cerById: {
        data: [],
        done: true,
        error: null,
    },
    cerCSR: {
        validateData: null,
        validateDone: false,
        validateError: null,
        isValidated: false,
        isInvalid: false,
        invalidMessage: null,
        sanInvalidData: []
    },
    logs: {
        done: false,
        data: [],
        error: null
    },
    cerExportDone: true,
    cerImportDone: true,
    cerCSRDetails: {
        done: false,
        data: [],
        error: null
    },
};

const cerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CER_FETCH_DATA:
            return {
                ...state,
                cer: {
                    ...state.cer,
                    data: {certificates: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case CER_DONE:
            return {...state, cer: {...state.cer, done: true, error: null}};
        case CER_PENDING:
            return {...state, cer: {...state.cer, done: false, error: null}};
        case CER_ERROR:
            return {...state, cer: {...state.cer, done: true, error: action.payload}, cerExportDone: true};
        case CER_ERROR_CLEAR:
            return {...state, cer: {...state.cer, error: null}};
        case CER_IMPORTED_FETCH_DATA:
            return {
                ...state,
                cerImported: {
                    ...state.cerImported,
                    data: {certificates: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case CER_IMPORTED_DONE:
            return {...state, cerImported: {...state.cerImported, done: true, error: null}};
        case CER_IMPORTED_PENDING:
            return {...state, cerImported: {...state.cerImported, done: false, error: null}};
        case CER_IMPORTED_ERROR:
            return {...state, cerImported: {...state.cerImported, done: true, error: action.payload}};
        case CER_DELEGATED_FETCH_DATA:
            return {
                ...state,
                cerDelegated: {
                    ...state.cerDelegated,
                    data: {certificates: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case CER_DELEGATED_DONE:
            return {...state, cerDelegated: {...state.cerDelegated, done: true, error: null}};
        case CER_DELEGATED_PENDING:
            return {...state, cerDelegated: {...state.cerDelegated, done: false, error: null}};
        case CER_DELEGATED_ERROR:
            return {...state, cerDelegated: {...state.cerDelegated, done: true, error: action.payload}};
        case CER_DELEGATED_ERROR_CLEAR:
            return {...state, cerDelegated: {...state.cerDelegated, error: null}};
        case CER_FETCH_CERT_TYPES:
            return {
                ...state,
                cerTypes: {...state.cerTypes, data: action.payload.response}
            };
        case CER_FETCH_CERT_TYPES_DONE:
            return {...state, cerTypes: {...state.cerTypes, done: true, error: null}};
        case CER_FETCH_CERT_TYPES_PENDING:
            return {...state, cerTypes: {...state.cerTypes, done: false, error: null}};
        case CER_FETCH_CERT_TYPES_ERROR:
            return {...state, cerTypes: {...state.cerTypes, done: true, error: action.payload}};
        case CER_CSR_VALIDATE:
            return {...state, cerCSR: {...state.cerCSR, validateData: action.payload, isValidated: true}};
        case CER_CSR_VALIDATE_DONE:
            return {...state, cerCSR: {...state.cerCSR, isValidated: true, validateError: null}};
        case CER_CSR_VALIDATE_PENDING:
            return {...state, cerCSR: {...state.cerCSR, isValidated: false, validateError: null}};
        case CER_CSR_VALIDATE_ERROR:
            return {...state, cerCSR: {...state.cerCSR, isValidated: true, validateError: action.payload}};
        case CER_CSR_INVALID_CERTIFICATE:
            return {
                ...state,
                cerCSR: {
                    ...state.cerCSR,
                    isValidated: true,
                    isInvalid: true,
                    invalidMessage: action.payload.errorSubType,
                    sanInvalidData: action.payload.errorData
                }
            };
        case CER_CSR_CLEAR_VALIDATION:
            return {
                ...state,
                cerCSR: {...initialState.cerCSR}
            };
        case CER_EXPORT_PENDING:
            return {...state, cerExportDone: false};
        case CER_EXPORT_DONE:
        case CER_EXPORT_ERROR:
            return {...state, cerExportDone: true};
        case CER_IMPORT_PENDING:
            return {...state, cerImportDone: false};
        case CER_IMPORT_DONE:
        case CER_IMPORT_ERROR:
            return {...state, cerImportDone: true};
        case CER_FETCH_LOGS:
            return {...state, logs: {...state.logs, data: action.payload.response}};
        case CER_FETCH_LOGS_DONE:
            return {...state, logs: {...state.logs, done: true, error: null}};
        case CER_FETCH_LOGS_ERROR:
            return {...state, logs: {...state.logs, done: true, error: action.payload}};
        case CER_FETCH_LOGS_PENDING:
            return {...state, logs: {...state.logs, done: false, error: null}};
        case CER_BY_ID_FETCH_DATA:
            return {...state, cerById: {...state.cerById, data: action.payload.response}};
        case CER_BY_ID_DONE:
            return {...state, cerById: {...state.cerById, done: true, error: null}};
        case CER_BY_ID_ERROR:
            return {...state, cerById: {...state.cerById, done: true, error: action.payload}};
        case CER_BY_ID_PENDING:
            return {...state, cerById: {...state.cerById, done: false, error: null}};
        case CER_BY_ID_CLEAR:
            return {...state, cerById: initialState.cerById};
        case CER_CSR_DETAILS_FETCH_DATA:
            return {
                ...state,
                cerCSRDetails: {
                    ...state.cerCSRDetails,
                    data: action.payload.response
                }
            };
        case CER_CSR_DETAILS_DONE:
            return {...state, cerCSRDetails: {...state.cerCSRDetails, done: true, error: null}};
        case CER_CSR_DETAILS_PENDING:
            return {...state, cerCSRDetails: {...state.cerCSRDetails, done: false, error: null}};
        case CER_CSR_DETAILS_ERROR:
            return {...state, cerCSRDetails: {...state.cerCSRDetails, done: true, error: action.payload}};
        case CER_CER_CLEAR_STATE_DATA:
            return {...state, cer: initialState.cer};
        case CER_DELEGATED_CLEAR_STATE_DATA:
            return {...state, cerDelegated: initialState.cerDelegated};
        case CER_IMPORTED_CLEAR_STATE_DATA:
            return {...state, cerImported: initialState.cerImported};
        case CER_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default cerReducer;