import axios from "axios";
import {cloneDeep, isEmpty} from "lodash";
import {saveAs} from "file-saver";
import {getOrgNames, handleErrors} from "./commonActions";

export const CER_FETCH_DATA = 'CER_FETCH_DATA';
export const CER_PENDING = 'CER_PENDING';
export const CER_DONE = 'CER_DONE';
export const CER_ERROR = 'CER_ERROR';
export const CER_ERROR_CLEAR = 'CER_ERROR_CLEAR';
export const CER_DELEGATED_FETCH_DATA = 'CER_DELEGATED_FETCH_DATA';
export const CER_DELEGATED_PENDING = 'CER_DELEGATED_PENDING';
export const CER_DELEGATED_DONE = 'CER_DELEGATED_DONE';
export const CER_DELEGATED_ERROR = 'CER_DELEGATED_ERROR';
export const CER_DELEGATED_ERROR_CLEAR = 'CER_DELEGATED_ERROR_CLEAR';
export const CER_BY_ID_FETCH_DATA = 'CER_BY_ID_FETCH_DATA';
export const CER_BY_ID_PENDING = 'CER_BY_ID_PENDING';
export const CER_BY_ID_DONE = 'CER_BY_ID_DONE';
export const CER_BY_ID_ERROR = 'CER_BY_ID_ERROR';
export const CER_BY_ID_CLEAR = 'CER_BY_ID_CLEAR';
export const CER_FETCH_CERT_TYPES_DONE = 'CER_FETCH_CERT_TYPES_DONE';
export const CER_FETCH_CERT_TYPES_PENDING = 'CER_FETCH_CERT_TYPES_PENDING';
export const CER_FETCH_CERT_TYPES = 'CER_FETCH_CERT_TYPES';
export const CER_FETCH_CERT_TYPES_ERROR = 'CER_FETCH_CERT_TYPES_ERROR';
export const CER_CSR_VALIDATE = 'CER_CSR_VALIDATE';
export const CER_CSR_VALIDATE_DONE = 'CER_CSR_VALIDATE_DONE';
export const CER_CSR_VALIDATE_PENDING = 'CER_CSR_VALIDATE_PENDING';
export const CER_CSR_VALIDATE_ERROR = 'CER_CSR_VALIDATE_ERROR';
export const CER_CSR_INVALID_CERTIFICATE = 'CER_CSR_INVALID_CERTIFICATE';
export const CER_CSR_CLEAR_VALIDATION = 'CER_CSR_CLEAR_VALIDATION';
export const CER_EXPORT_PENDING = 'CER_EXPORT_PENDING';
export const CER_EXPORT_DONE = 'CER_EXPORT_DONE';
export const CER_EXPORT_ERROR = 'CER_EXPORT_ERROR';
export const CER_FETCH_LOGS = 'CER_FETCH_LOGS';
export const CER_FETCH_LOGS_DONE = 'CER_FETCH_LOGS_DONE';
export const CER_FETCH_LOGS_ERROR = 'CER_FETCH_LOGS_ERROR';
export const CER_FETCH_LOGS_PENDING = 'CER_FETCH_LOGS_PENDING';
export const CER_CER_CLEAR_STATE_DATA = 'CER_CER_CLEAR_STATE_DATA';
//imported certificates
export const CER_IMPORT_DONE = 'CER_IMPORT_DONE';
export const CER_IMPORT_ERROR = 'CER_IMPORT_ERROR';
export const CER_IMPORT_PENDING = 'CER_IMPORT_PENDING';
export const CER_IMPORTED_FETCH_DATA = 'CER_IMPORTED_FETCH_DATA';
export const CER_IMPORTED_PENDING = 'CER_IMPORTED_PENDING';
export const CER_IMPORTED_DONE = 'CER_IMPORTED_DONE';
export const CER_IMPORTED_ERROR = 'CER_IMPORTED_ERROR';
export const CER_IMPORTED_CLEAR_STATE_DATA = 'CER_IMPORTED_CLEAR_STATE_DATA';
//certificate CSR details
export const CER_CSR_DETAILS_FETCH_DATA = 'CER_CSR_DETAILS_FETCH_DATA';
export const CER_CSR_DETAILS_DONE = 'CER_CSR_DETAILS_DONE';
export const CER_CSR_DETAILS_ERROR = 'CER_CSR_DETAILS_ERROR';
export const CER_CSR_DETAILS_PENDING = 'CER_CSR_DETAILS_PENDING';
export const CER_DELEGATED_CLEAR_STATE_DATA = 'CER_DELEGATED_CLEAR_STATE_DATA';

export const CER_CLEAR_STATE_DATA = 'CER_CLEAR_STATE_DATA';

let apiURL = process.env.REACT_APP_API_URL, apiUrlES = process.env.REACT_APP_API_ES_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};
//initial values for certificate filters
const initialFilters = {
    searchString: "",
    cerIds: [],
    status: ["active", "expired", "revoked"],
    sort: {
        order: "DESC",
        orderBy: "start_date"
    }
};

//initial values for imported certificates filters
const initialFiltersImported = {
    status: ["active", "expired"],
    sort: {
        order: "DESC",
        orderBy: "imported_time"
    }
};

//initial values for delegated certificates filters
const initialFiltersDelegated = {
    searchString: "",
    cerIds: [],
    status: ["active", "expired", "revoked"],
    sort: {
        order: "DESC",
        orderBy: "start_date"
    }
};

/**
 * Fetches the selected the certificate logs
 *
 * @param {number} certificateId the certificate id
 * @param {string} type if is imported or internal certificates
 */
export const fetchCertificateLogs = (certificateId, type = undefined) => async dispatch => {
    dispatch({type: CER_FETCH_LOGS_PENDING});
    try {
        let path = apiURL;
        type ? path += '/logs/certificate-imported' : path += '/logs/certificate';
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const response = await axios.post(path, {
            queryParameters: {
                certificate_id: certificateId,
                order: "DESC",
                orderBy: "log_timestamp",
            },
            returnValues: [
                "id",
                "log_timestamp",
                "short_message"
            ]
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            dispatch({type: CER_FETCH_LOGS, payload: isEmpty(response.data) ? {response: []} : response.data});
        }
        dispatch({type: CER_FETCH_LOGS_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_FETCH_LOGS_ERROR));
    }
};

/**
 * Fetches all the certificates
 *
 * @param {object} pagination number of results per page
 * @param {object} filters filters that are applied
 */
export const fetchCertificates = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: CER_PENDING});
    try {
        let appIds = []
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);

        const appResponse = await axios.post(apiURL + '/application/self', {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId
            },
            returnValues: ["id", "name"]
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(appResponse?.data?.response)) {
            await Promise.all(appResponse.data.response.map(async app => {
                appIds.push(app.id);
            }));

            const cerResponse = await axios.post(apiURL + '/certificate/search', {
                organization_id: orgId,
                queryParameters: {
                    application_id: appIds,
                    index: pageIndex,
                    limit: pagination.currentRowsPerPage,
                    order: filtersCopy.sort.order,
                    orderBy: filtersCopy.sort.orderBy,
                    status: filtersCopy.status,
                    withInfo: true
                },
                returnValues: [
                    "id",
                    "application_name",
                    "type",
                    "cert_template",
                    "issued_by",
                    "start_date",
                    "end_date",
                    "status",
                    "application_id",
                    "description",
                    "contact_person",
                    "is_notification_on"
                ]
            }, {headers: {Authorization: authorizationToken}});

            cerResponse ? dispatch({type: CER_FETCH_DATA, payload: cerResponse.data}) : dispatch({
                type: CER_FETCH_DATA,
                payload: {response: [], totalRows: 0}
            });
        } else {
            dispatch({type: CER_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: CER_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};

/**
 * fetch all the filtered certificates data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const filterFetchCertificates = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: CER_PENDING});
    try {
        let cerIds = [], appIds = [];
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const appResponse = await axios.post(apiURL + '/application/self', {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId
            },
            returnValues: ["id", "name"]
        }, {headers: {Authorization: authorizationToken}});

        await Promise.all(appResponse.data.response.map(org => appIds.push(org.id)));

        const cerESResponse = await axios.post(apiUrlES + '/essearch/certificate', {
            searchstring: filtersCopy.searchString,
            attributes: [
                "application_name",
                "contact_person",
                "issued_by",
                "description",
                "type",
                "start_date",
                "end_date",
                "file_name",
                "file_modified_date",
                "subject_alternative_name",
                "common_name",
                "organization",
                "organizational_unit",
                "locality",
                "state",
                "country",
                "email",
                "key_algorithm",
                "key_length",
                "signature_algorithm",
            ],
            status: filters.status,
            organization_id: orgId,
            application_id: appIds
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(cerESResponse?.data)) {
            appIds = [];
            await Promise.all(cerESResponse.data.map(cer => {
                cerIds.push(parseInt(cer._source.id));
                appIds.push(parseInt(cer._source.application_id));
                return cer;
            }));

            //remove duplicate values
            appIds = [...new Set(appIds)];

            const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
            const cerResponse = await axios.post(apiURL + '/certificate/search', {
                organization_id: orgId,
                queryParameters: {
                    id: cerIds,
                    index: pageIndex,
                    limit: pagination.currentRowsPerPage,
                    order: filtersCopy.sort.order,
                    orderBy: filtersCopy.sort.orderBy,
                    status: filtersCopy.status,
                    withInfo: true
                },
                returnValues: [
                    "id",
                    "application_name",
                    "type",
                    "cert_template",
                    "issued_by",
                    "start_date",
                    "end_date",
                    "status",
                    "application_id",
                    "description",
                    "contact_person",
                    "is_notification_on"
                ]
            }, {headers: {Authorization: authorizationToken}});

            cerResponse ? dispatch({type: CER_FETCH_DATA, payload: cerResponse.data}) : dispatch({
                type: CER_FETCH_DATA,
                payload: {response: [], totalRows: 0}
            });
        } else {
            dispatch({type: CER_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: CER_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};

/**
 * fetch the selected certificate data
 *
 * @param {number} id the id of certificate to fetch
 */
export const fetchCertificateById = id => async dispatch => {
    dispatch({type: CER_BY_ID_CLEAR});
    dispatch({type: CER_BY_ID_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const query = {
            organization_id: orgId,
            queryParameters: {
                id: [id]
            },
            returnValues: ["id", "type", "cert_template", "issued_by", "start_date", "end_date", "status", "application_id", "description", "contact_person", "is_notification_on"]
        }

        const cerResponse = await axios.post(apiURL + '/certificate/search', query, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(cerResponse?.data.response)) {
            let data = cerResponse.data.response[0];
            const appResponse = await axios.post(apiURL + '/application/self', {
                organization_id: orgId,
                queryParameters: {
                    organization_id: orgId,
                    status: ["active"],
                    id: [data.application_id]
                },
                returnValues: ["id", "name"]
            }, {headers: {Authorization: authorizationToken}});
            if (appResponse) {
                data.application_name = appResponse.data.response[0].name;
                data.application_id = appResponse.data.response[0].id;
            }
            dispatch({type: CER_BY_ID_FETCH_DATA, payload: {response: [data]}});
        } else {
            dispatch(fetchCertificates());
        }
        dispatch({type: CER_BY_ID_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_BY_ID_ERROR));
    }
};

/**
 * make a call to API to add a new certificate
 *
 * @param {object} values object with all the necessary input values to create a new certificate
 */
export const addNewCertificate = values => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const response = await axios.post(apiURL + '/certificate/add', {
            organization_id: orgId,
            data: {
                application_id: values.application_id,
                type: values.type,
                cert_template: values.cert_template,
                duration: values.duration,
                contact_person: values.contact_person,
                description: values.description,
                file: values.file,
                file_name: values.file_name,
                file_modified_date: values.file_modified_date
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};

/**
 * retrieve all the application from the current organization which user typed in autocomplete
 *
 * @param {object} value the application name which will be searched
 */
export const searchCertificateApplication = value => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        let appIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const appResponse = await axios.post(apiURL + '/application/self', {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId,
                status: ["active"],
                writeInfo: true
            },
            returnValues: ["id"]
        }, {headers: {Authorization: authorizationToken}});

        await Promise.all(appResponse.data.response.map(org => appIds.push(org.id)));

        const response = await axios.post(apiUrlES + '/essearch/application', {
            searchstring: value,
            requestor_org: orgId,
            application_id: appIds,
            attributes: ["name"],
            status: ["active"],
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.data;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};


/**
 * revokes the selected certificate from the list
 *
 * @param {number} id id of the certificate being revoked
 * @param {string} revokeReason the reason why the certificate was revoked
 */
export const revokeCertificate = (id, revokeReason) => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/certificate/update', {
            organization_id: orgId,
            data: {
                id: id,
                status: "revoked",
                revoke_reason: revokeReason
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};

/**
 * deletes the selected certificate from the list. The certificate itself isn't really deleted forever, it just gets flagged in order not to appear on the table.
 *
 * @param {number} id id of the certificate being deleted
 * @param {string} type if is imported certificate or normal certificate
 */
export const deleteCertificate = (id, type = "normal") => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        let deletePath = type === "imported" ? '/certificate/imported/delete' : '/certificate/delete';
        const response = await axios.post(apiURL + deletePath, {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, type === "imported" ? CER_IMPORT_ERROR : CER_ERROR));
    }
};

/**
 * renews the selected certificate from the list
 *
 * @param {object} values id of the certificate being revoked
 */
export const renewCertificate = values => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/certificate/add', {
            organization_id: orgId,
            data: {
                id: values.cer_id,
                application_id: values.application_id,
                type: values.type,
                cert_template: values.cert_template,
                duration: values.duration,
                contact_person: values.contact_person,
                description: values.description,
                file: values.file,
                status: values.status,
                file_name: values.file_name,
                file_modified_date: values.file_modified_date
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};

/**
 * updates the certificate with new information
 *
 * @param {object} values values necessary to update the certificate, in this case only contact_person and description is necessary.
 * @param {string} type if is imported certificate or normal certificate
 */
export const updateCertificate = (values, type = "normal") => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        let query = {
            organization_id: orgId,
            data: {
                id: values.id,
                contact_person: values.contact_person,
                description: values.description,
                is_notification_on: values.is_notification_on
            }
        },
            updatePath = type === "imported" ? '/certificate/imported/update' : '/certificate/update';
        const response = await axios.post(apiURL + updatePath, query, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, type === "imported" ? CER_IMPORT_ERROR : CER_ERROR));
    }
};

/**
 * function to validate the uploaded CSR file and returns the CSR data
 *
 * @param {string} type if it's a validated or clear errors messages
 * @param {string} file the base64 encoded file content
 * @param {string} appName application name to validate with CSR
 * @param {string} cerTypeId certificate type to validate
 */
export const validateCSR = (type, file, appName, cerTypeId) => async dispatch => {
    if (type === "clear") {
        dispatch({type: CER_CSR_CLEAR_VALIDATION});
    } else {
        dispatch({type: CER_CSR_VALIDATE_PENDING});
        dispatch({type: CER_CSR_CLEAR_VALIDATION});
        try {
            const authorizationToken = sessionStorage.getItem('authorizationToken');
            const orgId = parseInt(localStorage.getItem('organizationId'));
            let data;

            if (type === "import") {
                data = {
                    file: file,
                    type: type
                }
            } else {
                data = {
                    application_name: appName,
                    file: file,
                    organization_id: orgId,
                    certificate_template_id: cerTypeId
                }
            }

            const response = await axios.post(apiURL + '/certificate/upload', {
                organization_id: orgId,
                data: data
            }, {headers: {Authorization: authorizationToken}});
            if (!isEmpty(response.data)) {
                dispatch({type: CER_CSR_VALIDATE, payload: response.data});
                dispatch({type: CER_CSR_VALIDATE_DONE});
                return response.status;
            } else {
                dispatch({type: CER_CSR_CLEAR_VALIDATION});
                dispatch(handleErrors({response: {data: {message: "There was a unknown error validating the file."}}}, CER_ERROR));
            }
        } catch (err) {
            type === "import" ? dispatch(handleErrors(err, CER_IMPORT_ERROR)) : dispatch(handleErrors(err, CER_CSR_VALIDATE_ERROR));
        }
    }
};

/**
 * function to retrieve the CSV file and parse it so user can download it
 *
 * @param {string} type if is certificate or delegated certificates
 */
export const exportTableList = type => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    dispatch({type: CER_EXPORT_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        let path, fileName;
        switch (type) {
            case "my-certificates":
                path = "/certificate/download/csv";
                fileName = "mijn_certificaten_export.csv";
                break;
            case "imported-certificates":
                path = "/certificate/imported/download/csv"
                fileName = "geimporteerde_certificaten_export.csv";
                break;
            case "delegated-certificates":
                path = "/certificate/delegated/download/csv"
                fileName = "gedelegeerde_certificaten_export.csv";
                break;
            default:
                path = "/certificate/download/csv";
                fileName = "mijn_certificaten_export.csv";
        }

        const response = await axios.post(apiURL + path, {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId,
            }
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(response.data)) {
            let blob = new Blob(["\ufeff"+response.data.response], {type: 'data:text/csv;charset=utf-8;'});
            //using file-saver to create a .csv file and make the browser automatically download it
            saveAs(blob, fileName);
        }
        dispatch({type: CER_EXPORT_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_EXPORT_ERROR));
    }
};

const createSafeFileName = (unsafeName) => {
    let safeName = 'certificate_download';
    try {
        const name = unsafeName.replace(/[/\\?%*:|"<>]/g, '-');
        if (name && name.length > 0) {
            safeName = name;
        }
    } catch (err) {
        // We tried! No issue if we fail since we have a default name setup
        console.error("Error: Attempted to create safe filename but failed. Falling back to default.");
    }
    return safeName;
}

/**
 * function to retrieve the CSR file and parse it so user can download it
 *
 * @param {number} id the id of the certificate to download
 * @param {string} name name for the downloaded file
 * @param {string} type if is GlobalSIgn or S3 certificates
 */
export const downloadCSRFile = (id, name, type = undefined) => async dispatch => {
    dispatch({type: CER_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        let path = apiURL;

        if (type) {
            path = path + '/certificate/download/imported-file';
        } else {
            path = path + '/certificate/download/file';

        }
        const response = await axios.post(path, {
            organization_id: orgId,
            queryParameters: {
                id: id,
            }
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(response.data)) {
            if (type) {
                window.location = response.data.response.signedUrl;
            } else {
                //convert response base64 to a Blob
                const file = (response.data.response.file);

                let blob = new Blob([file], {type: 'text/plain;charset=utf-8'});
                //using file-saver to create a .csv file and make the browser automatically download it
                saveAs(blob, createSafeFileName(name) + '.crt');
            }
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_ERROR));
    }
};

/**
 * function to validate the uploaded CSR file and returns the CSR data
 *
 * @param {object} values object with all the necessary input values to import a certificate
 */
export const uploadCertificate = values => async dispatch => {
    dispatch({type: CER_IMPORT_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/certificate/import', {
            organization_id: orgId,
            data: {
                contact_person: values.contact_person,
                description: values.description,
                file: values.file,
                organization_id: orgId
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_IMPORT_ERROR));
    }
};

/**
 * Fetches all the certificates
 *
 * @param {object} pagination number of results per page
 * @param {object} filters filters that are applied
 */
export const fetchImportedCertificates = (pagination = initialPagination, filters = initialFiltersImported) => async dispatch => {
    dispatch({type: CER_IMPORTED_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);

        const cerResponse = await axios.post(apiURL + '/certificate/imported/search', {
            organization_id: orgId,
            queryParameters: {
                id: filtersCopy.searchString ? filtersCopy.cerIds : [],
                organization_id: orgId,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                status: filtersCopy.status,
                withInfo: true
            },
            returnValues: [
                "id",
                "name",
                "organization_name",
                "issued_by",
                "start_date",
                "end_date",
                "status",
                "description",
                "contact_person",
                "is_notification_on"
            ]
        }, {headers: {Authorization: authorizationToken}});

        cerResponse ? dispatch({type: CER_IMPORTED_FETCH_DATA, payload: cerResponse.data}) : dispatch({
            type: CER_IMPORTED_FETCH_DATA,
            payload: {response: [], totalRows: 0}
        });

        dispatch({type: CER_IMPORTED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_IMPORTED_ERROR));
    }
};

/**
 * fetch all the filtered certificates imported data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const filterFetchImportedCertificates = (pagination = initialFiltersDelegated, filters = initialFiltersDelegated) => async dispatch => {
    dispatch({type: CER_IMPORTED_PENDING});
    try {
        let cerIds = [];
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const cerESResponse = await axios.post(apiUrlES + '/essearch/certificate/imported', {
            searchstring: filtersCopy.searchString,
            attributes: [
                "name",
                "issued_by",
                "start_date",
                "end_date",
                "description",
                "contact_person",
                "end_date"
            ],
            status: filters.status,
            organization_id: orgId,
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(cerESResponse?.data)) {
            cerIds = [];
            await Promise.all(cerESResponse.data.map(cer => cerIds.push(parseInt(cer._source.id))));

            //remove duplicate values
            cerIds = [...new Set(cerIds)];
            filtersCopy.cerIds = cerIds;
            await dispatch(fetchImportedCertificates(pagination, filtersCopy))
        } else {
            dispatch({type: CER_IMPORTED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: CER_IMPORTED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_IMPORTED_ERROR));
    }
};

/**
 * fetch the selected imported certificate data
 *
 * @param {number} id the id of imported certificate to fetch
 */
export const fetchImportedCertificateById = id => async dispatch => {
    dispatch({type: CER_BY_ID_CLEAR});
    dispatch({type: CER_BY_ID_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const query = {
            organization_id: orgId,
            queryParameters: {
                id: [id],
                organization_id: orgId,
                withInfo: true
            },
            returnValues: [
                "id",
                "name",
                "organization_name",
                "issued_by",
                "start_date",
                "end_date",
                "status",
                "description",
                "contact_person",
                "is_notification_on"
            ]
        }

        const cerResponse = await axios.post(apiURL + '/certificate/imported/search', query, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(cerResponse?.data.response)) {
            let data = cerResponse.data.response[0];

            dispatch({type: CER_BY_ID_FETCH_DATA, payload: {response: [data]}});
        } else {
            dispatch(fetchCertificates());
        }
        dispatch({type: CER_BY_ID_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_BY_ID_ERROR));
    }
};

/**
 * Fetches all the certificates
 *
 * @param {object} pagination number of results per page
 * @param {object} filters filters that are applied
 */
export const fetchDelegatedCertificates = (pagination = initialPagination, filters = initialFiltersDelegated) => async dispatch => {
    dispatch({type: CER_DELEGATED_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);

        const cerResponse = await axios.post(apiURL + '/certificate/delegated', {
            organization_id: orgId,
            queryParameters: {
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                "cert.status": filtersCopy.status,
                withInfo: true,
                is_delegated: true
            },
            returnValues: [
                "id",
                "application_name",
                "organization_id",
                "type",
                "issued_by",
                "start_date",
                "end_date",
                "status"
            ]
        }, {headers: {Authorization: authorizationToken}});

        if (!isEmpty(cerResponse?.data.response)) {
            const data = await dispatch(getOrgNames(cerResponse.data, "organization_id"));
            dispatch({type: CER_DELEGATED_FETCH_DATA, payload: data});
        } else {
            dispatch({type: CER_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }

        dispatch({type: CER_DELEGATED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_DELEGATED_ERROR));
    }
};

/**
 * fetch all the filtered certificates data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const filterFetchDelegatedCertificates = (pagination = initialFiltersDelegated, filters = initialFiltersDelegated) => async dispatch => {
    dispatch({type: CER_DELEGATED_PENDING});
    try {
        let cerIds = [], appIds = [];
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const delegatedAppResponse = await axios.post(apiURL + '/application/search', {
            organization_id: orgId,
            queryParameters: {
                is_delegated: true,
                organization_id: orgId,
                status: ["active", "expired"]
            },
            returnValues: ["id"]
        }, {headers: {Authorization: authorizationToken}});

        await Promise.all(delegatedAppResponse.data.response.map(app => appIds.push(app.id)));

        const cerESResponse = await axios.post(apiUrlES + '/essearch/certificate', {
            searchstring: filtersCopy.searchString,
            attributes: [
                "application_name",
                "contact_person",
                "description",
                "type",
                "issued_by",
                "start_date",
                "end_date",
                "organization_name"
            ],
            status: filters.status,
            organization_id: [],
            application_id: appIds
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(cerESResponse?.data)) {
            appIds = [];
            await Promise.all(cerESResponse.data.map(cer => {
                cerIds.push(parseInt(cer._source.id));
                appIds.push(parseInt(cer._source.application_id));
                return cer;
            }));

            //remove duplicate values
            appIds = [...new Set(appIds)];

            const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
            const cerResponse = await axios.post(apiURL + '/certificate/delegated', {
                organization_id: orgId,
                queryParameters: {
                    "cert.id": cerIds,
                    index: pageIndex,
                    limit: pagination.currentRowsPerPage,
                    order: filtersCopy.sort.order,
                    orderBy: filtersCopy.sort.orderBy,
                    "cert.status": filtersCopy.status,
                    withInfo: true,
                    is_delegated: true
                },
                returnValues: [
                    "id",
                    "application_name",
                    "organization_id",
                    "type",
                    "issued_by",
                    "start_date",
                    "end_date",
                    "status"
                ]
            }, {headers: {Authorization: authorizationToken}});
            if (cerResponse) {
                const data = await dispatch(getOrgNames(cerResponse.data, "organization_id"));
                dispatch({type: CER_DELEGATED_FETCH_DATA, payload: data});
            } else {
                dispatch({type: CER_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
            }
        } else {
            dispatch({type: CER_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: CER_DELEGATED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_DELEGATED_ERROR));
    }
};

/**
 * Fetch a certificate CSR details
 *
 * @param {number} certificateId number of results per page
 */
export const fetchCertificateCSRDetails = (certificateId) => async dispatch => {
    dispatch({type: CER_CSR_DETAILS_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const cerResponse = await axios.post(apiURL + '/certificate/csr/details', {
            organization_id: orgId,
            queryParameters: {
                certificate_id: certificateId
            },
            returnValues: [
                "file_name",
                "file_modified_date",
                "common_name",
                "subject_alternative_name",
                "organization",
                "organizational_unit",
                "locality",
                "state",
                "country",
                "email",
                "key_algorithm",
                "key_length",
                "signature_algorithm"
            ]
        }, {headers: {Authorization: authorizationToken}});

        cerResponse ? dispatch({type: CER_CSR_DETAILS_FETCH_DATA, payload: cerResponse.data}) : dispatch({
            type: CER_CSR_DETAILS_FETCH_DATA,
            payload: {response: [], totalRows: 0}
        });
        dispatch({type: CER_CSR_DETAILS_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_CSR_DETAILS_ERROR));
    }
};

/**
 * clear the cerById data
 */
export const clearCerByIdData = () => async dispatch => {
    dispatch({type: CER_BY_ID_CLEAR});
};

/**
 * clears all the state data
 */
export const clearCerStateData = () => async dispatch => {
    dispatch({type: CER_CER_CLEAR_STATE_DATA});
};

/**
 * clears all the state data
 */
export const clearCerImportedStateData = () => async dispatch => {
    dispatch({type: CER_IMPORTED_CLEAR_STATE_DATA});
};

/**
 * clears all the state data
 */
export const clearCerDelegatedStateData = () => async dispatch => {
    dispatch({type: CER_DELEGATED_CLEAR_STATE_DATA});
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: CER_CLEAR_STATE_DATA});
};