import React from "react";
import PropTypes from "prop-types";
import {Link as ReactRouterDOMLink} from "react-router-dom";

/**
 * UUse a link to refer the user to another URL, e.g. a new page or a document.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/atoms/vl-ui-link" target="_blank">Link</a>
 *
 * @example ../../styleguide/atoms/Link.md
 */
const Link = props => {
    const { href, label, isNavLink, display, icon, iconPosition, isClose, isExternal, onClick, className } = props;
    let iconPositionClass = "", displayClass = "";

    iconPosition === "after" && (iconPositionClass = " vl-link__icon--after");
    iconPosition === "before" && (iconPositionClass = " vl-link__icon--before");
    display === "block" && (displayClass = " vl-link--block");
    display === "inline" && (displayClass = " vl-link--inline");

    return (
        isNavLink ?
            <ReactRouterDOMLink to={href} className={`vl-link${displayClass} ${className}`} onClick={onClick}>
                {isClose && <i className="vl-link__icon vl-link__icon--before vl-vi vl-vi-cross" aria-hidden="true"/>}
                {iconPosition === 'before' && <i className={`vl-link__icon vl-vi vl-vi-${icon}${iconPositionClass}`} aria-hidden="true"/>}
                {label}
                {isExternal && <i className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external" aria-hidden="true"/>}
                {iconPosition === 'after' && <i className={`vl-link__icon vl-vi vl-vi-${icon}${iconPositionClass}`} aria-hidden="true"/>}
            </ReactRouterDOMLink>
            :
            <a href={href} className={`vl-link${displayClass} ${className}`} target={isExternal ? '_blank' : '_self'} onClick={onClick} rel={isExternal ? 'noreferrer' : ''}>
                {isClose && <i className="vl-link__icon vl-link__icon--before vl-vi vl-vi-cross" aria-hidden="true"/>}
                {iconPosition === 'before' && <i className={`vl-link__icon vl-vi vl-vi-${icon}${iconPositionClass}`} aria-hidden="true"/>}
                {label}
                {iconPosition === 'after' && <i className={`vl-link__icon vl-vi vl-vi-${icon}${iconPositionClass}`} aria-hidden="true"/>}
            </a>
    );
};

Link.propTypes = {
    /**
     * [Required] link path name.
     */
    href: PropTypes.string.isRequired,
    /**
     * [Required] label to display inside link.
     */
    label: PropTypes.string.isRequired,
    /**
     * [Optional] to use like a react-router-dom Link.
     */
    isNavLink: PropTypes.bool,
    /**
     * [Optional] to display link a block element, taking the width of the parent container or inline element, that will follow the original flow of content.
     */
    display: PropTypes.oneOf(["block", "link"]),
    /**
     * [Optional] name of the icon to display.
     */
    icon: PropTypes.string,
    /**
     * [Optional] position of the icon
     */
    iconPosition: PropTypes.oneOf(["before", "after"]),
    /**
     * [Optional] if the component has a close icon.
     */
    isClose: PropTypes.bool,
    /**
     * [Optional] open the link in a new page.
     */
    isExternal: PropTypes.bool,
    /**
     * [Optional] event handler for when user clicks on the component.
     */
    onClick: PropTypes.func,
    /**
     * [Optional] adds apecific classes to the Link component
     */
    className: PropTypes.string
};

Link.defaultProps = {
    href: "/",
    label: "Anchor element",
    isNavLink: false,
    display: null,
    icon: null,
    iconPosition: null,
    isClose: false,
    isExternal: false,
    onClick: () => null
};

export default Link;