import React from "react";
import PropTypes from "prop-types";

/**
 * The loader component is an animation indicating that a page/website is being loaded.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/atoms/vl-ui-loader" target="_blank">Loader</a>
 *
 * @example ../../styleguide/atoms/Loader.md
 */
const Loader = props => {
    const {label, isDark} = props;

    return (
        <div className="vl-col--1-1">
            <div className={`vl-region${isDark ? ' vl-region--alt' : ''}`}>
                <div className="vl-u-align-center">
                    <div className={`vl-loader${isDark ? ' vl-loader--light' : ''}`} role="alert" aria-busy="true"/>
                    <p>
                        {label}
                    </p>
                </div>
            </div>
        </div>
    );
};

Loader.propTypes = {
    /**
     * [Required] the message to display while loading.
     */
    label: PropTypes.string.isRequired,
    /**
     * [Optional] a darker version of the loading.
     */
    isDark: PropTypes.bool
};

Loader.defaultProps = {
    label: "Laden",
    isDark: false
};

export default Loader;