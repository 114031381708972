export const required = value => (value || typeof value === 'number' ? undefined : 'required');

const maxLength = max => value => value && value.length > max ? `maxCharactersReached-${max}` : undefined;

export const maxLength70 = maxLength(70);

export const maxLength200 = maxLength(200);

export const maxLength255 = maxLength(255);

export const maxLength300 = maxLength(300);

export const maxLength500 = maxLength(500);

export const appNameInvalid = value => value ? 'appNameInvalid' : undefined;

export const domNameAlreadyExists = value => value ? 'domNameAlreadyExists' : undefined;

export const domNameInvalid = value => value ? 'domNameInvalid' : undefined;

export const appNameAlreadyExists = value => value ? 'appNameAlreadyExists' : undefined;

export const appNameDomainNotFound = value => value ? 'appNameDomainNotFound' : undefined;

const maxEmails = max => value => value && value.replace(/[^,]/g, "").length > max - 1 ? `maxEmailReached-${max}` : undefined;

export const maxEmailsOf5 = maxEmails(5);

export const requiredOneOfEach = value => (value ? undefined : 'requiredOneOfEach');

export const emailInvalid = value => value && value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ? undefined : 'emailInvalid';

