import axios from "axios";
import {isEmpty} from "lodash";
import {handleErrors} from "./commonActions";

export const THEME_SET_TABS_VISIBILITY = 'THEME_SET_TABS_VISIBILITY';
export const THEME_SET_USER_MENU_VISIBILITY = 'THEME_SET_USER_MENU_VISIBILITY';
export const THEME_USER_DATA_PENDING = 'THEME_USER_DATA_PENDING';
export const THEME_USER_DATA_DONE = 'THEME_USER_DATA_DONE';
export const THEME_USER_DATA_ERROR = 'THEME_USER_DATA_ERROR';
export const THEME_USER_DATA_FETCH = 'THEME_USER_DATA_FETCH';
export const THEME_USER_DATA_CLEAR = 'THEME_USER_DATA_CLEAR';
export const THEME_AUTH_DONE = 'THEME_AUTH_DONE';

export const THEME_NOTIFICATION_FETCH_DATA = 'THEME_NOTIFICATION_FETCH_DATA';
export const THEME_NOTIFICATION_PENDING = 'THEME_NOTIFICATION_PENDING';
export const THEME_NOTIFICATION_DONE = 'THEME_NOTIFICATION_DONE';
export const THEME_NOTIFICATION_ERROR = 'THEME_NOTIFICATION_ERROR';
export const THEME_NOTIFICATION_ERROR_CLEAR = 'THEME_NOTIFICATION_ERROR_CLEAR';

export const THEME_SET_SYSTEM_ERROR_MESSAGE = 'THEME_SET_SYSTEM_ERROR_MESSAGE';

const apiURL = process.env.REACT_APP_API_URL;

/**
 * show or hide the menu tabs if in org page
 * @param {boolean} value
 */
export const setTabsVisibility = value => dispatch => {
    dispatch({type: THEME_SET_TABS_VISIBILITY, payload: value});
};

/**
 * show or hide the user menu if in org page
 * @param {boolean} value
 */
export const setUserMenuVisibility = value => dispatch => {
    dispatch({type: THEME_SET_USER_MENU_VISIBILITY, payload: value});
};

/**
 * flag the application that authentication its done
 */
export const setAuth = () => dispatch => {
    dispatch({type: THEME_AUTH_DONE});
};

/**
 * fetch the user info (user name, roles)
 */
export const getUserInfo = () => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        if (orgId) {
            dispatch({type: THEME_USER_DATA_PENDING});
            const response = await axios.post(apiURL + '/roles/search', {
                organization_id: orgId
            }, {headers: {Authorization: authorizationToken}});

            if (!isEmpty(response.data)) {
                const respData = response.data.response;
                const userRoles = respData.roles.map(role => role.privilege);

                let userInfo = {
                    username: respData.firstName + ' ' + respData.lastName,
                    roles: userRoles,
                    hasAppWideRoles: respData.hasAppWideRoles
                };
                dispatch({type: THEME_USER_DATA_FETCH, payload: userInfo});
                const orgResponse = await axios.post(apiURL + '/organization/self', {
                    queryParameters: {},
                    returnValues: ["id", "name"]
                }, {headers: {"Authorization": authorizationToken}});
                if (!isEmpty(orgResponse.data)) {
                    localStorage.setItem('showOrgSwitchOption', JSON.stringify(orgResponse.data.response.length > 1));
                }
            }
        } else {
            const response = await axios.post(apiURL + '/roles/search', {
                checkHasAppWideRole: true
            }, {headers: {Authorization: authorizationToken}});
            if (!isEmpty(response.data)) {
                dispatch({
                    type: THEME_USER_DATA_FETCH,
                    payload: {
                        username: null,
                        roles: [],
                        hasAppWideRoles: response.data.hasAppWideRoles,
                        hasAdminPrivilege: response.data.hasAdminPrivilege,
                        hasAdminConfigPrivilege: response.data.hasAdminConfigPrivilege
                    }
                });
            }
        }
        dispatch({type: THEME_USER_DATA_DONE});
    } catch (err) {
        dispatch(handleErrors(err, THEME_USER_DATA_ERROR));
    }
}

/**
 * clear user info
 */
export const clearUserInfo = () => async dispatch => {
    dispatch({type: THEME_USER_DATA_CLEAR});
}

/**
 * Fetches all the users notifications logs
 *
 * @param {array} orgIds array of logged user organizations id (for organization page)
 */
export const fetchNotifications = orgIds => async dispatch => {
    dispatch({type: THEME_NOTIFICATION_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        let query;
        if (orgId) {
            query = {
                organization_id: orgId,
                queryParameters: {
                    organization_id: [orgId],
                    orderBy: "timestamp",
                    order: "DESC"
                },
                returnValues: ["id", "entity", "entity_id", "message", "is_read", "timestamp"]
            };
        } else {
            query = {
                queryParameters: {
                    organization_id: orgIds,
                    mode: "count"
                },
                returnValues: ["id", "organization_id"]
            };
        }
        const response = await axios.post(apiURL + '/notification/search', query, {headers: {"Authorization": authorizationToken}});
        if (response) {
            dispatch({type: THEME_NOTIFICATION_FETCH_DATA, payload: response.data});
        }
        dispatch({type: THEME_NOTIFICATION_DONE});
    } catch (err) {
        dispatch({type: THEME_NOTIFICATION_FETCH_DATA, payload: {response: [], totalRows: 0}});
        dispatch({type: THEME_NOTIFICATION_DONE});
        //dispatch(handleErrors(err, THEME_NOTIFICATION_ERROR));
    }
};

/**
 * function to use when component need a new data fetch
 */
export const needNotificationFetch = () => async dispatch => {
    dispatch(fetchNotifications([]));
};

/**
 * Mark notification as read
 *
 * @param {array|number} id the id of notification to mark as read
 */
export const notificationMarkAsRead = id => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        if (orgId) {
            let query = {
                organization_id: orgId,
                data: {
                    id: id.length ? id : [id]
                }
            };

            const response = await axios.post(apiURL + '/notification/read', query, {headers: {"Authorization": authorizationToken}});
            if (response) {
                return response.status;
            }
        }
    } catch (err) {
        dispatch(handleErrors(err, THEME_NOTIFICATION_ERROR));
    }
};