import React from "react";
import PropTypes from "prop-types";

/**
 * This is the fun part: We use a standard 12-column fluid responsive grid system.
 * The grid (.vl-grid) helps you to orderly lay out your page. It's entirely built on Flexbox.
 * You can compare the Grid element to the Row element in Bootstrap, or the Columns element in Bulma.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/grid" target="_blank">Grid</a>
 *
 * @example ../../../styleguide/grid/Grid.md
 */
const Grid = props => {
    const { stackSize, hAlignment, vAlignment, className, children} = props;

    return (
        <div className={`vl-grid${stackSize ? ' vl-grid--is-' + stackSize : ''}${hAlignment ? ' vl-grid--align-' + hAlignment : ''}${vAlignment ? ' vl-grid--v-' + vAlignment : ''}${className ? ` ${className}` : ''}`}>
            {children}
        </div>
    );
};

Grid.propTypes = {
    /**
     * [Optional] use this modifier to add a margin between the stacked columns.
     */
    stackSize: PropTypes.string,
    /**
     * [Optional] use this modifier to align one or more columns horizontally.
     */
    hAlignment: PropTypes.string,
    /**
     * [Optional] use this modifier to align one or more columns vertically.
     */
    vAlignment: PropTypes.string,
    /**
     * [Optional] adds a additional classes to component.
     */
    className: PropTypes.string,
    /**
     * [Required] display the content of component.
     */
    children: PropTypes.node.isRequired
};

Grid.defaultProps = {
    stackSize: null,
    hAlignment: null,
    vAlignment: null,
    className: null,
    children: "Grid"
};

export default Grid;