import {
    VAL_RULES_PENDING,
    VAL_RULES_DONE,
    VAL_RULES_FETCH_DATA,
    VAL_RULES_ERROR,
    VAL_RULES_ERROR_CLEAR,
    VAL_RULES_CLEAR_STATE_DATA,
    VAL_RULE_BY_ID_DONE,
    VAL_RULE_BY_ID_FETCH_DATA,
    VAL_RULE_BY_ID_CLEAR,
    VAL_RULE_BY_ID_ERROR,
    VAL_RULE_BY_ID_PENDING,
} from "../actions/validationRulesActions";

const initialState = {
    valRules: {
        done: true,
        data: {
            rules: [],
            totalItems: 0
        },
        error: null,
    },
    valRuleById: {
        data: [],
        done: true,
        error: null,
    },
};

const valRuleReducer = (state = initialState, action) => {
    switch (action.type) {
        case VAL_RULES_FETCH_DATA:
            return {
                ...state,
                valRules: {
                    ...state.valRules,
                    data: {rules: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case VAL_RULES_DONE:
            return {...state, valRules: {...state.valRules, done: true, error: null}};
        case VAL_RULES_PENDING:
            return {...state, valRules: {...state.valRules, done: false, error: null}};
        case VAL_RULES_ERROR:
            return {...state, valRules: {...state.valRules, done: true, error: action.payload}};
        case VAL_RULES_ERROR_CLEAR:
            return {...state, valRules: {...state.valRules, error: null}};
        case VAL_RULE_BY_ID_FETCH_DATA:
            return {...state, valRuleById: {...state.valRuleById, data: action.payload.response}};
        case VAL_RULE_BY_ID_DONE:
            return {...state, valRuleById: {...state.valRuleById, done: true, error: null}};
        case VAL_RULE_BY_ID_ERROR:
            return {...state, valRuleById: {...state.valRuleById, done: true, error: action.payload}};
        case VAL_RULE_BY_ID_PENDING:
            return {...state, valRuleById: {...state.valRuleById, done: false, error: null}};
        case VAL_RULE_BY_ID_CLEAR:
            return {...state, valRuleById: initialState.valRuleById};
        case VAL_RULES_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default valRuleReducer;