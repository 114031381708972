import React from "react";
import "./Input.scss";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Icon from "../../atoms/Icon";

/**
 * An input field allows the user to enter information in your application, e.g.: an email address or a password.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-input-field" target="_blank">Input</a>
 *
 * @example ../../../styleguide/form/Input.md
 */
const Input = props => {
    const {
        type,
        placeholder,
        label,
        isBlock,
        isSmall,
        isSearch,
        isDisabled,
        input,
        meta: {dirty, error},
        className,
        onClearButtonClick
    } = props;
    const {t} = useTranslation('validations');
    /**
     * handling various types of errors messages
     *
     * @param {string} error type of error
     * @returns {string} the error message itself
     * @public
     */
    const handleMessageErrors = error => {
        if (error.includes("maxCharactersReached")) {
            return t('maxCharactersReached', {max: error.split('-')[1]});
        }
        return t(`${error}`);
    };

    return (
            <>
                <label className={isSearch ? 'vl-u-visually-hidden' : 'vl-form__label'} htmlFor={input.name}>{label}</label>
                <input
                    name={input.name}
                    className={`vl-input-field${isBlock ? ' vl-input-field--block' : ''}${isDisabled ? ' vl-input-field--disabled' : ''}${isSmall ? ' vl-input-field--small' : ''}${dirty && error && error !== 'required' ? ' vl-input-field--error' : ''}${className ? ` ${className}` : ''}`}
                    type={type}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    aria-describedby={dirty && error && error !== 'required' ? `input-field-${input.name}-error` : null}
                    {...input}
                    autoComplete={isSearch ? 'off' : null}
                />
                {
                    isSearch && input.value.trim() &&
                    <button type="button" className="vl-link vl-input-field__input-clear-button" onClick={onClearButtonClick}>
                        <Icon name="close"/>
                    </button>
                }
                {dirty && error && (error !== 'required' && error !== 'appNameAlreadyExists') &&
                <p className="vl-form__error" id={`input-field-${input.name}-error`}>{handleMessageErrors(error)}</p>}
            </>
    );
}

Input.propTypes = {
    /**
     * metadata props received from redux-form to validate input component.
     * @ignore
     */
    meta: PropTypes.shape({
        dirty: PropTypes.bool,
        error: PropTypes.string
    }).isRequired,
    /**
     * props received from redux-form to connect input component to Redux.
     * @ignore
     */
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string
    }).isRequired,
    /**
     * [Optional] the type of input field
     */
    type: PropTypes.string,
    /**
     * [Required] label to display with input.
     */
    label: PropTypes.node.isRequired,
    /**
     * [Optional] display a placeholder for the input.
     */
    placeholder: PropTypes.string,
    /**
     * [Optional] check if input is a block element and add the class 'vl-input-field--block'.
     */
    isBlock: PropTypes.bool,
    /**
     * c[Optional] heck if input is smaller and add the class 'vl-input-field--small'.
     */
    isSmall: PropTypes.bool,
    /**
     * [Optional] display input in form of a search bar.
     */
    isSearch: PropTypes.bool,
    /**
     * [Optional] check if input is disabled and add the class 'vl-input-field--disabled'.
     */
    isDisabled: PropTypes.bool,
    /**
     * [Optional] add additional classes to input.
     */
    className: PropTypes.string,
    /**
     * [Optional] callback function when clear button is clicked (only in search mode).
     */
    onClearButtonClick: PropTypes.func
}

Input.defaultProps = {
    meta: null,
    input: null,
    type: "text",
    label: "",
    placeholder: null,
    isBlock: false,
    isSmall: false,
    isSearch: false,
    isDisabled: false,
    className: null,
    onClearButtonClick: () => null
}

export default Input;