import {
    EMA_FETCH_DATA,
    EMA_DONE,
    EMA_ERROR,
    EMA_PENDING,
    EMA_ERROR_CLEAR,
    EMA_CLEAR_STATE_DATA,
    EMA_DYN_FETCH_DATA,
    EMA_DYN_DONE,
    EMA_DYN_ERROR,
    EMA_DYN_PENDING,
    EMA_DYN_ERROR_CLEAR,
} from "../actions/emailSettingsActions";

const initialState = {
    ema: {
        done: false,
        data: {
            emailSettings: [],
            totalItems: 0
        },
        error: null,
    },
    emaDyn: {
        done: false,
        data: {
            dynamicTemplates: [],
            totalItems: 0
        },
        error: null,
    },
};

const emaReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMA_FETCH_DATA:
            return {
                ...state,
                ema: {
                    ...state.ema,
                    data: { emailSettings: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case EMA_DONE:
            return { ...state, ema: { ...state.ema, done: true, error: null}};
        case EMA_PENDING:
            return { ...state, ema: { ...state.ema, done: false, error: null}};
        case EMA_ERROR:
            return { ...state, ema: { ...state.ema, done: true, error: action.payload}};
        case EMA_ERROR_CLEAR:
            return { ...state, ema: { ...state.ema, error: null } };
        case EMA_DYN_FETCH_DATA:
            return {
                ...state,
                emaDyn: {
                    ...state.emaDyn,
                    data: { dynamicTemplates: action.payload.response, totalItems: action.payload.totalRows }
                }
            };
        case EMA_DYN_DONE:
            return { ...state, emaDyn: { ...state.emaDyn, done: true, error: null } };
        case EMA_DYN_PENDING:
            return { ...state, emaDyn: { ...state.emaDyn, done: false, error: null } };
        case EMA_DYN_ERROR:
            return { ...state, emaDyn: { ...state.emaDyn, done: true, error: action.payload } };
        case EMA_DYN_ERROR_CLEAR:
            return { ...state, emaDyn: { ...state.emaDyn, error: null } };
        case EMA_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default emaReducer;