import React, {useState} from "react";
import "./FunctionalHeader.scss";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import Notification from "./Notification";
import Popover from "../../molecules/Popover/Popover";
import PopoverItem from "../../molecules/Popover/PopoverItem";
import PopoverSeparator from "../../molecules/Popover/PopoverSeparator";
import Link from "../../atoms/Link";
import Icon from "../../atoms/Icon";

/**
 * The header component is located at the top of the page. This component consists on the Header itself plus the "Certificates", "Applications", "Domains" tabs, the user and the notification icon
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-functional-header" target="_blank">Functional Header</a>
 *
 */
const FunctionalHeader = props => {
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
    const {hasTabs, hasSelects, userData, selectSpecialOrg, systemParam} = props;
    const {t} = useTranslation('header');

    let manualLink = {
        hasLink: false,
        link: null
    };
    systemParam.data.some(param => {
        if (param.key === "manual") {
            manualLink = {
                hasLink: true,
                link: param.value
            };
            return true;
        }
        return false;
    });

    /**
     * function to handle sign out
     */
    const handleSignOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location = process.env.REACT_APP_ACM_LOGOUT_REDIRECT_URL;
    }

    /**
     * validates if the prop hasTabs exists in the component in order to render the three
     * main tabs, Domain, Applications and Certificate
     */
    const renderTabs = () =>
        hasTabs &&
        <div className="vl-tabs__wrapper">
            <div className="vl-tabs" role="tablist">
                {
                    userData.user.roles.some(userRole => (userRole === process.env.REACT_APP_ORG_CERTIFICATE_ADMIN_ORG) ||
                        (userRole === process.env.REACT_APP_ORG_CERTIFICATE_ADMIN_APP) ||
                        (userRole === process.env.REACT_APP_APP_APPLICATION_ADMIN) ||
                        (userRole === process.env.REACT_APP_APP_CERTIFICATE_ADMIN) ||
                        (userRole === process.env.REACT_APP_APP_CONFIG_ADMIN) ||
                        (userRole === process.env.REACT_APP_APP_HELPDESK)
                    ) &&
                    <NavLink
                        activeClassName="vl-tab--active"
                        className="vl-tab"
                        to="/certificaten"
                        role="tab"
                    >
                        {t("tabCertificates")}
                    </NavLink>
                }
                <NavLink
                    activeClassName="vl-tab--active"
                    className="vl-tab"
                    to="/toepassingen"
                    role="tab"
                >
                    {t("tabApplications")}
                </NavLink>
                <NavLink
                    activeClassName="vl-tab--active"
                    className="vl-tab"
                    to="/domeinen"
                    role="tab"
                >
                    {t("tabDomains")}
                </NavLink>
            </div>
        </div>;

    return (
        <header className="vl-functional-header" role="banner">
            <div className="vl-layout">
                <div className="vl-functional-header__row">
                    <div className="vl-functional-header__content">
                        <p className="vl-u-text--small">{t("preTitle")}</p>
                    </div>
                </div>
                <div className="vl-functional-header__row">
                    <div className="vl-functional-header__content">
                        <p className="vl-title vl-u-text--bold">{t("title")}</p>
                    </div>
                    <div>
                        {
                            hasSelects &&
                            <Popover
                                isOpened={value => setIsUserMenuOpened(value)}
                                label={
                                    <p>
                                        {localStorage.getItem('organizationName')}<Icon
                                        name={isUserMenuOpened ? "nav-up" : "nav-down"} iconPosition="after|before"/>
                                    </p>
                                }
                                isCustomIcon
                                position="left"
                                customIconPosition={{top: "-1rem", bottom: 0, left: "19rem"}}
                            >
                                <PopoverItem>
                                    <p className="vl-u-text--xsmall">{t("selectDropdownLoggedInAs")}</p>
                                    <p className="vl-u-text--small vl-u-text--bold">
                                        {userData.user?.username}
                                    </p>
                                    <p className="vl-u-text--xsmall">{t('selectDropdownFor')}</p>
                                    <button
                                        type="button"
                                        className="vl-link vl-u-text--small vl-u-text--bold"
                                        onClick={() => props.toggleOrgInfoModal(true, {
                                            name: localStorage.getItem('organizationName'),
                                            id: parseInt(localStorage.getItem('organizationId')),
                                            organization_code: localStorage.getItem('organizationCode')
                                        })}
                                    >
                                        {localStorage.getItem('organizationName')}
                                    </button>
                                </PopoverItem>
                                <PopoverSeparator/>
                                {
                                    localStorage.getItem("showOrgSwitchOption") === "true" &&
                                    <>
                                        <PopoverItem>
                                            <Icon name="synchronize" iconPosition="before" size="small"/>
                                            <Link
                                                className="vl-u-text--small"
                                                label={t("selectDropdownChangeOrganization")}
                                                href="/organisatie"
                                                isNavLink
                                                onClick={() => setIsUserMenuOpened(false)}
                                            />
                                        </PopoverItem>
                                        <PopoverSeparator/>
                                    </>
                                }
                                <PopoverItem>
                                    <button type="button" className="vl-link vl-u-text--small"
                                            onClick={handleSignOut}>{t("selectDropdownSignOut")}</button>
                                </PopoverItem>
                            </Popover>
                        }
                        {
                            hasSelects &&
                            <Notification/>
                        }
                        {
                            userData.user.hasAppWideRoles &&
                            <button
                                type="button"
                                className="vl-link"
                                style={{color: "#333332"}}
                                title={t('btnAllRequestsTitle')}
                                onClick={() => selectSpecialOrg("/configuratie/admin")}
                            >
                                <Icon name="file-tasks-check" iconPosition="before"/>
                            </button>
                        }
                        {
                            userData.user.hasAppWideRoles &&
                            <Popover label={<Icon name="cog" size="large" iconPosition="before"
                                                  title={t('btnConfigurationTitle')}/>}
                                     isCustomIcon>
                                <PopoverItem>
                                    {
                                        hasSelects ?
                                            <Link
                                                className="vl-u-text--small"
                                                isNavLink
                                                href="/configuratie/validatieregels"
                                                label={t('selectDropdownValRulesLabel')}
                                            />
                                            :
                                            <button
                                                className="vl-u-text--small vl-link"
                                                type="button"
                                                onClick={() => selectSpecialOrg("/configuratie/validatieregels")}
                                            >
                                                {t('selectDropdownValRulesLabel')}
                                            </button>
                                    }
                                </PopoverItem>
                                <PopoverItem>
                                    {
                                        hasSelects ?
                                            <Link
                                                className="vl-u-text--small"
                                                isNavLink
                                                href="/configuratie/certificaat_templates"
                                                label={t('selectDropdownCerTemplatesLabel')}
                                            />
                                            :
                                            <button
                                                className="vl-u-text--small vl-link"
                                                type="button"
                                                onClick={() => selectSpecialOrg("/configuratie/certificaat_templates")}
                                            >
                                                {t('selectDropdownCerTemplatesLabel')}
                                            </button>
                                    }
                                </PopoverItem>
                                <PopoverItem>
                                    {
                                        hasSelects ?
                                            <Link
                                                className="vl-u-text--small"
                                                isNavLink
                                                href="/configuratie/email"
                                                label={t('selectDropdownEmailConfigLabel')}
                                            />
                                            :
                                            <button
                                                className="vl-u-text--small vl-link"
                                                type="button"
                                                onClick={() => selectSpecialOrg("/configuratie/email")}
                                            >
                                                {t('selectDropdownEmailConfigLabel')}
                                            </button>
                                    }
                                </PopoverItem>
                                <PopoverItem>
                                    {
                                        hasSelects ?
                                            <Link
                                                className="vl-u-text--small"
                                                isNavLink
                                                href="/configuratie/parameter"
                                                label={t('selectDropdownParameterLabel')}
                                            />
                                            :
                                            <button
                                                className="vl-u-text--small vl-link"
                                                type="button"
                                                onClick={() => selectSpecialOrg("/configuratie/parameter")}
                                            >
                                                {t('selectDropdownParameterLabel')}
                                            </button>
                                    }
                                </PopoverItem>
                                <PopoverItem>
                                    {
                                        hasSelects ?
                                            <Link
                                                className="vl-u-text--small"
                                                isNavLink
                                                href="/configuratie/whitelist-origin"
                                                label={t('selectDropdownExternalAPIConfigLabel')}
                                            />
                                            :
                                            <button
                                                className="vl-u-text--small vl-link"
                                                type="button"
                                                onClick={() => selectSpecialOrg("/configuratie/whitelist-origin")}
                                            >
                                                {t('selectDropdownExternalAPIConfigLabel')}
                                            </button>
                                    }
                                </PopoverItem>
                            </Popover>
                        }
                        {
                            manualLink.hasLink &&
                            <a
                                href={manualLink.link}
                                target="_blank"
                                rel="noreferrer"
                                className="vl-link"
                                style={{color: "#333332"}}
                                title={t('btnDocumentationTitle')}
                            >
                                <Icon name="question-mark"/>
                            </a>
                        }
                    </div>
                </div>
                <div className="vl-functional-header__sub">
                    <div className="vl-grid">
                        <nav className="vl-col--9-12 vl-col--8-12--m vl-col--1-1--s">
                            {hasTabs && renderTabs()}
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

FunctionalHeader.propTypes = {
    /**
     * [Required] allows the Tabs "Certificates", "Applications", "Domains".
     */
    hasTabs: PropTypes.bool.isRequired,
    /**
     * [Required] header icons to be shown or hidden.
     */
    hasSelects: PropTypes.bool.isRequired,
    /**
     * [Required] the user info data object (e.g.: username)
     */
    userData: PropTypes.object.isRequired,
    /**
     * [Required] the React application system parameters (e.g.: help manual link)
     */
    systemParam: PropTypes.object.isRequired
};

FunctionalHeader.defaultProps = {
    hasTabs: false,
    hasSelects: false,
    userData: null,
    systemParam: null
};

export default FunctionalHeader;
