import React from "react";
import "./Notification.scss";
import history from "../../../history";
import moment from "moment";
import {cloneDeep} from "lodash";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchNotifications, notificationMarkAsRead} from "../../../redux/actions/themeActions";
import Popover from "../../molecules/Popover/Popover";
import PopoverItem from "../../molecules/Popover/PopoverItem";
import PopoverSeparator from "../../molecules/Popover/PopoverSeparator";
import NotificationRow from "./NotificationRow";
import Icon from "../../atoms/Icon";
import Badge from "../../atoms/Badge";

/**
 * Component that will be used to show the notifications located on the header.
 */
class Notification extends React.Component {
    state = {
        notifications: [],
        numberOfUnreadNotifications: 0,
        totalOfNotifications: 0,
        showAll: false,
        closePopover: false
    }

    componentDidMount() {
        const orgId = parseInt(localStorage.getItem('organizationId'));
        orgId && this.fetchNotifications();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {notification} = this.props;
        let numberOfUnreadNotifications = 0;

        if (prevProps.notification.done !== notification.done && notification.done) {
            notification.data.notifications.map(notification => !notification.is_read && numberOfUnreadNotifications++);

            this.setState({
                notifications: notification.data.notifications,
                totalOfNotifications: parseInt(notification.data.totalItems),
                numberOfUnreadNotifications
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    /**
     * fetch all the user notifications for that organization
     */
    fetchNotifications() {
        clearTimeout(this.timer);
        this.props.fetchNotifications([]);
        this.timer = setTimeout(() => {
            this.fetchNotifications();
        }, 60000);
    }

    /**
     * shows all the notifications
     */
    fetchAllNotifications = () => {
        this.setState({showAll: true});
    };

    /**
     * mark a notification as read and switching to the entity
     *
     * @param {object} notification the notification to mark as read
     */
    async handleOnClick(notification) {
        let notifications = this.state.notifications, notificationId = notification.id;
        if (!notification.is_read) {
            this.switchToEntity(notification);
            const resp = await this.props.notificationMarkAsRead(notificationId);

            if (resp === 200) {
                notifications.find(notification => notification.id === notificationId && (notification.is_read = true));
                this.setState(prevState => ({
                    notifications,
                    numberOfUnreadNotifications: prevState.numberOfUnreadNotifications - 1,
                    closePopover: true
                }));
            }
        } else {
            this.setState({
                closePopover: true
            });
            this.switchToEntity(notification);
        }
    }

    /**
     * mark a notification as read
     *
     * @param {object} e the event object itself
     * @param {object} notification the notification to mark as read
     */
    async markAsRead(e, notification) {
        e.stopPropagation();
        let notifications = this.state.notifications, notificationId = notification.id;
        const resp = await this.props.notificationMarkAsRead(notificationId);

        if (resp === 200) {
            notifications.find(notification => notification.id === notificationId && (notification.is_read = true));
            this.setState(prevState => ({
                notifications,
                numberOfUnreadNotifications: prevState.numberOfUnreadNotifications - 1,
            }));
        }
    }

    /**
     * mark all the user notification as read
     */
    markAllAsRead = async () => {
        let notifications = this.state.notifications;
        let notReadNotifications = notifications.filter(notification => !notification.is_read);

        notReadNotifications = notReadNotifications.map(notification => notification.id);

        const resp = await this.props.notificationMarkAsRead(notReadNotifications);

        if (resp === 200) {
            notifications.map(notification => notification.is_read = true);
            this.setState({
                notifications,
                numberOfUnreadNotifications: 0
            });
        }
    };

    /**
     * extract information of entity and redirect to corresponding page
     *
     * @param {object} notification the notification object to extract information
     */
    switchToEntity = notification => {
        switch (notification.entity) {
            case "APPLICATION":
                history.push({
                    pathname: "/toepassingen",
                    state: {entityId: notification.entity_id}
                });
                break;
            case "PENDING_APPLICATION":
                history.push("/toepassingen");
                break;
            case "DOMAIN":
                history.push({
                    pathname: "/domeinen",
                    state: {entityId: notification.entity_id}
                });
                break;
            case "PENDING_DOMAIN":
                history.push("/domeinen");
                break;
            case "CERTIFICATE":
                history.push({
                    pathname: "/certificaten",
                    state: {entityId: notification.entity_id}
                });
                break;
            case "CERTIFICATE_IMPORTED":
                history.push({
                    pathname: "/certificaten",
                    state: {entityId: notification.entity_id, tab: "imported-certificates"}
                });
                break;
            case "VALIDATION_RULE":
                history.push({
                    pathname: "/domeinen",
                    state: {entityId: notification.entity_id, tab: "my-validation-rules"}
                });
                break;
            default:
                break;
        }
    };

    render() {
        const {t} = this.props;
        const {notifications, numberOfUnreadNotifications, totalOfNotifications, showAll, closePopover} = this.state;

        let notificationsCopy = cloneDeep(notifications);

        //at beginning, show only the 3 latest notifications
        if (!showAll) {
            notificationsCopy = notificationsCopy.slice(0, 3);
        }

        return (
            <div className="vl-notification">
                {
                    numberOfUnreadNotifications > 0 &&
                    <Badge color="warning" size="xsmall"
                           className="vl-notification__counter"><span>{numberOfUnreadNotifications}</span></Badge>
                }
                <Popover
                    className="vl-popover--large"
                    label={<Icon name="alarm" size="large" iconPosition="before" title={t('notificationsDropdownTitle')}/>}
                    isCustomIcon
                    close={closePopover}
                    isOpened={e => e && this.setState({closePopover: false})}
                >
                    <PopoverItem>
                        <div className="vl-functional-header__row vl-u-spacer--small">
                        <span className="vl-u-text--small vl-u-text--bold">
                            {t("notificationsDropdownTitle")}
                        </span>
                            {
                                numberOfUnreadNotifications > 0 && notifications.some(notification => notification.is_read === false) &&
                                <button className=" vl-u-text--small vl-link"
                                        onClick={this.markAllAsRead}>
                                    {t("notificationsDropdownMarkAllAsRead")}
                                </button>
                            }
                        </div>
                    </PopoverItem>
                    <PopoverSeparator/>
                    {
                        totalOfNotifications > 0 ?
                            notificationsCopy.map((notification, index) =>
                                <React.Fragment key={index}>
                                    <NotificationRow isUnread={!notification.is_read} onClick={() => this.handleOnClick(notification)}>
                                        {
                                            !notification.is_read &&
                                            <button
                                                className="vl-u-text--xsmall vl-link"
                                                onClick={e => this.markAsRead(e, notification)
                                                }>
                                            </button>
                                        }
                                        <div>
                                            <p className="vl-u-text--xsmall">{moment(notification.timestamp).format('DD/MM/YYYY')}</p>
                                            <p className="vl-u-text--small">{notification.message}</p>
                                        </div>
                                    </NotificationRow>
                                    <PopoverSeparator/>
                                </React.Fragment>
                            )
                            :
                            <PopoverItem>
                                <div className="vl-u-align-center vl-u-spacer">
                                    <p className="vl-u-text--small">{t("notificationsNoNotifications")}</p>
                                </div>
                            </PopoverItem>
                    }
                    {
                        (totalOfNotifications > 3 && !showAll) &&
                        <PopoverItem>
                            <div className="vl-u-align-center vl-u-spacer--small">
                                <button
                                    className=" vl-u-text--small vl-link"
                                    onClick={this.fetchAllNotifications}
                                >
                                    {t("notificationsDropdownSeeAll")}
                                </button>
                            </div>
                        </PopoverItem>
                    }
                </Popover>
            </div>
        );
    }
}

const translationWrapped = withTranslation('header')(Notification);

const mapStateToProps = (state) => {
    return {
        notification: state.theme.notification
    };
};

export default connect(mapStateToProps, {
    fetchNotifications,
    notificationMarkAsRead
})(translationWrapped);
