import axios from "axios";
import {getUserInformation, handleErrors} from "./commonActions";

export const PARAM_FETCH_DATA = 'PARAM_FETCH_DATA';
export const PARAM_PENDING = 'PARAM_PENDING';
export const PARAM_DONE = 'PARAM_DONE';
export const PARAM_ERROR = 'PARAM_ERROR';
export const PARAM_ERROR_CLEAR = 'PARAM_ERROR_CLEAR';
export const PARAM_CLEAR_STATE_DATA = 'PARAM_CLEAR_STATE_DATA';
export const PARAM_SYSTEM_PARAMETERS_FETCH_DATA = 'PARAM_SYSTEM_PARAMETERS_FETCH_DATA';
export const PARAM_SYSTEM_PARAMETERS_PENDING = 'PARAM_SYSTEM_PARAMETERS_PENDING';
export const PARAM_SYSTEM_PARAMETERS_DONE = 'PARAM_SYSTEM_PARAMETERS_DONE';
export const PARAM_SYSTEM_PARAMETERS_ERROR = 'PARAM_SYSTEM_PARAMETERS_ERROR';

let apiURL = process.env.REACT_APP_API_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

/**
 * fetch all the parameters data
 *
 * @param {object} pagination receive a object with the pagination configurations
 * @param {boolean} asSystemParameters fetch parameters data to use in the React application
 */
export const fetchParameters = (pagination = initialPagination, asSystemParameters = false) => async dispatch => {
    dispatch({type: asSystemParameters ? PARAM_SYSTEM_PARAMETERS_PENDING : PARAM_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const query = {
            organization_id: orgId,
            queryParameters: {
                index: asSystemParameters ? undefined : pageIndex,
                limit: asSystemParameters ? undefined : pagination.currentRowsPerPage,
                orderBy: "id",
                order: "DESC"
            },
            returnValues: asSystemParameters ? ["key", "description", "value"] : ["id", "key", "value", "description", "last_updated", "modified_by"]
        }

        const response = await axios.post(apiURL + '/parameter/search', query, {headers: {Authorization: authorizationToken}});
        if (response) {
            if (!asSystemParameters) {
                await Promise.all(response.data.response.map(async param => {
                    const userInfoResponse = await dispatch(getUserInformation(orgId, param.modified_by));
                    param.modified_by = userInfoResponse.name ? `${userInfoResponse.name} ${userInfoResponse.last_name}` : null;

                    return param;
                }));
                dispatch({type: PARAM_FETCH_DATA, payload: response.data});
            } else {
                dispatch({type: PARAM_SYSTEM_PARAMETERS_FETCH_DATA, payload: response.data});
            }
        }
        dispatch({type: asSystemParameters ? PARAM_SYSTEM_PARAMETERS_DONE : PARAM_DONE});
    } catch (err) {
        dispatch(handleErrors(err, asSystemParameters ? PARAM_SYSTEM_PARAMETERS_ERROR : PARAM_ERROR));
    }
};

/**
 * make a call to API to add a new parameter
 *
 * @param {object} values object with all the necessary input values to create a new parameter
 */
export const addNewParameter = values => async dispatch => {
    dispatch({type: PARAM_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/parameter/add', {
            organization_id: orgId,
            data: {
                key: values.key,
                value: values.value,
                description: values.description
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, PARAM_ERROR));
    }
};

/**
 * update the selected parameter
 *
 * @param {any} values values necessary to update the parameter.
 */
export const updateParameter = values => async dispatch => {
    dispatch({type: PARAM_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/parameter/update', {
            organization_id: orgId,
            data: {
                id: values.id,
                key: values.key,
                value: values.value,
                description: values.description
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, PARAM_ERROR));
    }
};

/**
 * deletes the selected parameter from the list
 *
 * @param {number} id id of the parameter to be deleted.
 */
export const deleteParameter = id => async dispatch => {
    dispatch({type: PARAM_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/parameter/delete', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, PARAM_ERROR));
    }
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: PARAM_CLEAR_STATE_DATA});
};