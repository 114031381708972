import React from "react";
import PropTypes from "prop-types";

/**
 * Display a item for the Popover component. The component itself doesn't do much without Popover component.
 */
const PopoverItem = ({children, className}) => {
    return (
        <li className={`vl-popover__link-list__item${className ? ` ${className}` : ""}`}>{children}</li>
    );
};

PopoverItem.propTypes = {
    /**
     * [Required] component that will be displayed in the popover item.
     */
    children: PropTypes.node.isRequired,
    /**
     * [Optional] add additional classes to popover item.
     */
    className: PropTypes.string
};

PopoverItem.defaultProps = {
    children: "Popover Item",
    className: null
};

export default PopoverItem;