import React from "react";
import PropTypes from "prop-types";

/**
 * Create a row item for datatable.
 * The component itself doesn't do much without Datatable component.
 */
const DatatableRow = ({ row, titles, onMouseEnter, onMouseLeave }) => {
    return (
        <tr key={`tr-${row.id}`} onMouseEnter={() => onMouseEnter(row.id)} onMouseLeave={onMouseLeave}>
            {
                titles.map((title, index) =>
                    <td key={`td-${index}`} className={title.align ? `vl-u-align-${title.align}` : null}>{row[title.key]}</td>
                )
            }
        </tr>
    )
};

DatatableRow.propTypes = {
    /**
     * [Required] row data object.
     */
    row: PropTypes.object.isRequired,
    /**
     * [Required] title object which contains all attributes for sorting and filtering.
     */
    titles: PropTypes.array.isRequired,
    /**
     * [Optional] function callback when mouse hover over row.
     */
    onMouseEnter: PropTypes.func,
    /**
     * [Optional] function callback when mouse hover leave the row.
     */
    onMouseLeave: PropTypes.func
};

DatatableRow.defaultProps = {
    row: {},
    titles: [],
    onMouseEnter: () => null,
    onMouseLeave: () => null
};

export default DatatableRow;