import React from "react";
import PropTypes from "prop-types";

/**
 * Use a modal dialog to inform the user about unsaved changes when he leaves the page. A modal dialog alerts the user and requires interaction before the site can be used again.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/js-components/vl-ui-modal" target="_blank">Modal</a>
 *
 * @example ../../../styleguide/molecules/Modal.md
 */
const Modal = props => {
    const {id, size, toggle, customZIndex, children} = props;

    return (
        <>
            <div style={customZIndex ? {zIndex: customZIndex} : null} className={`vl-modal-backdrop${!toggle ? ' vl-modal-backdrop--hidden' : ''}`}/>
            <div className="vl-modal">
                <div
                    className={`vl-modal-dialog${size ? ` vl-modal-dialog--${size}` : ''}${toggle ? ' vl-modal-dialog--opened' : ''}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-modal="true"
                    aria-hidden={toggle}
                    aria-labelledby={`modal-toggle-${id}-title`}
                    aria-describedby={`modal-toggle-${id}-description`}
                >
                    {toggle && children}
                </div>
            </div>
        </>
    );
};

Modal.propTypes = {
    /**
     * [Required] assign a id for accessibility labels.
     */
    id: PropTypes.string.isRequired,
    /**
     * [Optional] add a wider variant of the modal.
     */
    size: PropTypes.oneOf(["medium", "large"]),
    /**
     * [Required] display the content of the modal.
     */
    children: PropTypes.node.isRequired,
    /**
     * [Required] toggle modal open and close.
     */
    toggle: PropTypes.bool.isRequired,
    /**
     * [Optional] add a custom z-index value to modal
     */
    customZIndex: PropTypes.string
};

Modal.defaultProps = {
    id: "modal",
    size: null,
    children: "Modal",
    toggle: false,
    customZIndex: null
};

export default Modal;