import axios from "axios";
import {cloneDeep, isEmpty} from "lodash";
import {handleErrors} from "./commonActions";

export const CER_TEMP_FETCH_DATA = 'CER_TEMP_FETCH_DATA';
export const CER_TEMP_PENDING = 'CER_TEMP_PENDING';
export const CER_TEMP_DONE = 'CER_TEMP_DONE';
export const CER_TEMP_ERROR = 'CER_TEMP_ERROR';
export const CER_TEMP_ERROR_CLEAR = 'CER_TEMP_ERROR_CLEAR';
export const CER_CLEAR_STATE_DATA = 'CER_CLEAR_STATE_DATA';
export const CER_TEMP_BY_DOMAIN_FETCH_DATA = 'CER_TEMP_BY_DOMAIN_FETCH_DATA';
export const CER_TEMP_BY_DOMAIN_PENDING = 'CER_TEMP_BY_DOMAIN_PENDING';
export const CER_TEMP_BY_DOMAIN_DONE = 'CER_TEMP_BY_DOMAIN_DONE';
export const CER_TEMP_BY_DOMAIN_ERROR = 'CER_TEMP_BY_DOMAIN_ERROR';

let apiURL = process.env.REACT_APP_API_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

/**
 * Fetches all the certificate templates
 *
 * @param {boolean} hasPagination if need paginated data
 * @param {object} pagination number of results per page
 * @param {object} cerTempKeyNames the key usages business name fetched from parameters table
 */
export const fetchCertificateTemplates = (hasPagination = true, pagination = initialPagination, cerTempKeyNames = null) => async dispatch => {
    dispatch({type: CER_TEMP_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        let query = {};

        if (hasPagination) {
            const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
            query = {
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                orderBy: "id",
                order: "DESC"
            };
        }
        const response = await axios.post(apiURL + '/certificate-template/search', {
            organization_id: orgId,
            queryParameters: query,
            returnValues: ["id", "certificate_name", "encryption_algorithm", "key_length", "key_usage", "extended_key_usage", "additional_instructions"]
        }, {headers: {Authorization: authorizationToken}});

        if (!isEmpty(response?.data.response)) {
            //assign the business name from parameters table
            let data = cloneDeep(response.data.response);
            if (cerTempKeyNames) {
                data = data.map(cerTemp => {
                    let keyUsage = cerTemp.key_usage?.map(keyUsage => {
                        cerTempKeyNames.some(keyUsageName => {
                            if (`key_usage.${keyUsage}` === keyUsageName.key || `EC_key_usage.${keyUsage}` === keyUsageName.key || `RSA_key_usage.${keyUsage}` === keyUsageName.key) {
                                keyUsage = keyUsageName.value;
                                return true;
                            }
                            return false;
                        });
                        return keyUsage;
                    });

                    let extendedKeyUsage = cerTemp.extended_key_usage?.map(extendedKeyUsage => {
                        cerTempKeyNames.some(extendedKeyUsageName => {
                            if (`extended_key_usage.${extendedKeyUsage}` === extendedKeyUsageName.key || `EC_extended_key_usage.${extendedKeyUsage}` === extendedKeyUsageName.key || `RSA_extended_key_usage.${extendedKeyUsage}` === extendedKeyUsageName.key) {
                                extendedKeyUsage = extendedKeyUsageName.value;
                                return true;
                            }
                            return false;
                        });
                        return extendedKeyUsage;
                    });
                    return {...cerTemp, key_usage: keyUsage, extended_key_usage: extendedKeyUsage};
                });
            }
            dispatch({type: CER_TEMP_FETCH_DATA, payload: {...response.data, response: data}});
        } else {
            dispatch({type: CER_TEMP_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: CER_TEMP_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_TEMP_ERROR));
    }
};

/**
 * Fetches all the certificates types according to application received
 *
 * @param {string} appName the application name itself
 * @param {string} status the status of the domain to be searched upon
 */
export const fetchCertificateTypesByDomain = (appName, status) => async dispatch => {
    dispatch({type: CER_TEMP_BY_DOMAIN_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        //retrieve only the domain
        const domainName = appName.split("/")[0];
        const response = await axios.post(apiURL + '/certificate-template/search-per-domain', {
            organization_id: orgId,
            queryParameters: {
                domain_name: domainName,
                status: status
            }
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(response?.data.response)) {
            dispatch({type: CER_TEMP_BY_DOMAIN_FETCH_DATA, payload: response.data});
        } else {
            dispatch({type: CER_TEMP_BY_DOMAIN_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: CER_TEMP_BY_DOMAIN_DONE});
    } catch (err) {
        dispatch(handleErrors(err, CER_TEMP_BY_DOMAIN_ERROR));
    }
};

/**
 * creates a new certificate template
 *
 * @param {object} values all the form information that will be used to create the new certificate template
 */
export const addNewCertificateTemplate = values => async dispatch => {
    dispatch({type: CER_TEMP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/certificate-template/add', {
            organization_id: orgId,
            data: {
                certificate_name: values.certificate_name,
                encryption_algorithm: values.encryption_algorithm,
                key_length: parseInt(values.key_length),
                key_usage: values.key_usage,
                extended_key_usage: values.extended_key_usage || [],
                additional_instructions: values.additional_instructions || ""
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return 200;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_TEMP_ERROR));
    }
};

/**
 * deletes the selected certificate template from the list
 *
 * @param {number} id id of the certificate template to be deleted.
 */
export const deleteCertificateTemplate = id => async dispatch => {
    dispatch({type: CER_TEMP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/certificate-template/delete', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_TEMP_ERROR));
    }
};

/**
 * updates the certificate template with new information
 *
 * @param {object} cerTempId the id of certificate template to update
 * @param {object} values values necessary to update the certificate template
 */
export const updateCertificateTemplate = (cerTempId, values) => async dispatch => {
    dispatch({type: CER_TEMP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/certificate-template/update', {
            organization_id: orgId,
            data: {
                id: cerTempId,
                certificate_name: values.certificate_name,
                encryption_algorithm: values.encryption_algorithm,
                key_length: parseInt(values.key_length),
                key_usage: values.key_usage || false,
                extended_key_usage: values.extended_key_usage || [],
                additional_instructions: values.additional_instructions || ""
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, CER_TEMP_ERROR));
    }
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: CER_CLEAR_STATE_DATA});
};