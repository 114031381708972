import React from "react";
import PropTypes from "prop-types";

/**
 * Create a table pagination for datatable.
 * The component itself doesn't do much without Datatable component.
 */
class DatatablePagination extends React.PureComponent {
    static propTypes = {
        /**
         * [Required] the total number fo rows.
         */
        totalItems: PropTypes.number.isRequired,
        /**
         * [Required] the number of items to show per page.
         */
        rowsPerPage: PropTypes.number.isRequired,
        /**
         * [Required] the current page of datatable.
         */
        currentPage: PropTypes.number.isRequired,
        /**
         * [Required] function callback when page change occurs.
         */
        pageChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        totalItems: 0,
        rowsPerPage: 10,
        currentPage: 1,
        pageChange: () => null
    };

    state = {
        pagination: [],
        totalItems: 0,
        rowsPerPage: this.props.rowsPerPage,
        currentPage: 1,
        numberOfPages: 0
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.totalItems !== this.props.totalItems || prevProps.currentPage !== this.props.currentPage || prevProps.rowsPerPageHasChanged !== this.props.rowsPerPageHasChanged) {
            this.setNumberOfPages();
        }
    }

    /**
     * create a pagination button
     * @param {number} number the current number of the pagination button
     * @param {number} currentPage the current page number
     * @returns {JSX.Element} button element
     */
    addButton(number, currentPage) {
        return (
            <li className="vl-pager__element" key={`pager-${number}`}>
                {
                    number === currentPage ?
                        <div className="vl-pager__element">
                            {number}
                        </div>
                        :
                        <button
                            key={`page-${number}`}
                            type="button"
                            className="vl-pager__element__cta vl-link vl-link--bold"
                            onClick={() => {
                                this.setState({currentPage: number}, () => this.props.pageChange(this.state.currentPage));
                            }}
                            data-testid={`page-${number}`}
                        >
                            {number}
                        </button>
                }
            </li>
        );
    }

    /**
     * function to handle the pagination of datatable
     */
    setNumberOfPages = () => {
        const {totalItems, rowsPerPage, currentPage} = this.props;
        let pagination = [], numberOfPages;

        numberOfPages = Math.ceil(totalItems / rowsPerPage);
        if (numberOfPages > 0 && totalItems > rowsPerPage) {
            if (numberOfPages <= 6) {
                for (let i = 1; i <= numberOfPages; i++) {
                    pagination.push(this.addButton(i, currentPage));
                }
            } else {

                // Always print first page button
                pagination.push(this.addButton(1, currentPage));

                // Print "..." only if currentPage is > 3
                if (currentPage > 3) {
                    pagination.push("...");
                }

                // special case where last page is selected...
                if (currentPage === numberOfPages) {
                    pagination.push(this.addButton(currentPage - 2, currentPage));
                }

                // Print previous number button if currentPage > 2
                if (currentPage > 2) {
                    pagination.push(this.addButton(currentPage - 1, currentPage));
                }

                //Print current page number button as long as it not the first or last page
                if (currentPage !== 1 && currentPage !== numberOfPages) {
                    pagination.push(this.addButton(currentPage, currentPage));
                }

                //print next number button if currentPage < lastPage - 1
                if (currentPage < numberOfPages - 1) {
                    pagination.push(this.addButton(currentPage + 1, currentPage));
                }

                // special case where first page is selected...
                if (currentPage === 1) {
                    pagination.push(this.addButton(currentPage + 2, currentPage));
                }

                //print "..." if currentPage is < lastPage -2
                if (currentPage < numberOfPages - 2) {
                    pagination.push("...");
                }

                //Always print last page button if there is more than 1 page
                pagination.push(this.addButton(numberOfPages, currentPage));
            }
        }
        this.setState({numberOfPages, pagination, totalItems, currentPage, rowsPerPage});
    };

    changePage = (direction) => {
        direction === 'next' ? this.setState(prevState => ({currentPage: prevState.currentPage + 1}), () => this.props.pageChange(this.state.currentPage)) : this.setState(prevState => ({currentPage: prevState.currentPage - 1}), () => this.props.pageChange(this.state.currentPage));
    };

    render() {
        const {pagination, numberOfPages, totalItems, rowsPerPage, currentPage} = this.state;

        return (
            numberOfPages > 0 && totalItems > rowsPerPage &&
            <div className="vl-pager">
                <ul className="vl-pager__list">
                    <li className="vl-pager__element">
                        <span className="vl-u-visually-hidden">Rij</span>
                        <strong>{(currentPage * rowsPerPage) - (rowsPerPage - 1)}-{currentPage === numberOfPages ? totalItems : currentPage * rowsPerPage}</strong> van {totalItems}
                    </li>
                    <li className="vl-pager__element">
                        {
                            currentPage === 1 ?
                                <div className="vl-u-text--bold">
                                    <i className="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-left-fat"
                                       aria-hidden="true"/>
                                    Vorige<span className="vl-u-visually-hidden"> {rowsPerPage} rijen</span>
                                </div>
                                :
                                <button
                                    type="button"
                                    className="vl-pager__element__cta vl-link vl-link--bold"
                                    onClick={() => this.changePage('prev')}
                                    data-testid="prevButton"
                                >
                                    <i className="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-left-fat"
                                       aria-hidden="true"/>
                                    Vorige<span className="vl-u-visually-hidden"> {rowsPerPage} rijen</span>
                                </button>
                        }

                    </li>
                    {pagination}
                    <li className="vl-pager__element">
                        {
                            currentPage === numberOfPages ?
                                <div className="vl-u-text--bold">
                                    Volgende
                                    <span className="vl-u-visually-hidden"> {rowsPerPage} rijen</span>
                                    <i className="vl-link__icon vl-link__icon--after vl-vi vl-vi-arrow-right-fat"
                                       aria-hidden="true"/>
                                </div>
                                :
                                <button
                                    type="button"
                                    className="vl-pager__element__cta vl-link vl-link--bold"
                                    onClick={() => this.changePage('next')}
                                    data-testid="nextButton"
                                >
                                    Volgende
                                    <span className="vl-u-visually-hidden"> {rowsPerPage} rijen</span>
                                    <i className="vl-link__icon vl-link__icon--after vl-vi vl-vi-arrow-right-fat"
                                       aria-hidden="true"/>
                                </button>
                        }
                    </li>
                </ul>
            </div>
        );
    }
}

export default DatatablePagination;