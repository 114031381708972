import React from "react";
import "../../assets/scss/app.scss";
import "@govflanders/vl-ui-core";
import {BrowserRouter, Router} from "react-router-dom";
import history from "../../history";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {config as i18nextConfig} from "../../translations";
import Routes from "./Routes";
import ErrorModal from "../Error/ErrorModal";
import AuthComponent from "./AuthComponent";
import OrganizationInfo from "../Organization/OrganizationInfo";

i18next.use(LanguageDetector).init(i18nextConfig);

const App = () => {
    return (
        <AuthComponent
            authority={process.env.REACT_APP_ACM_AUTHORITY}
            clientId={process.env.REACT_APP_ACM_CLIENT_ID}
            clientSecret={process.env.REACT_APP_ACM_CLIENT_SECRET}
            responseType="code"
            redirectUri={process.env.REACT_APP_ACM_LOGIN_REDIRECT_URL}
            disablePKCE={process.env.REACT_APP_ENV === "stage"}
            scope={process.env.REACT_APP_ENV === "stage" || process.env.REACT_APP_ENV === "production"  ? "openid vo profile" : "openid profile"}
        >
            <BrowserRouter>
                <I18nextProvider i18n={i18next}>
                    <Router history={history}>
                        <React.StrictMode>
                            <Routes/>
                            <OrganizationInfo/>
                            <ErrorModal/>
                        </React.StrictMode>
                    </Router>
                </I18nextProvider>
            </BrowserRouter>
        </AuthComponent>
    )
};

export default App;