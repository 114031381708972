import resources from "./resources";

const detectionOptions = {
    order: ['navigator', 'cookie', 'localStorage', 'path', 'subdomain', 'queryString', 'htmlTag'],
    lookupFromPathIndex: 0
};

export const config = {
    lng: 'nl',
    fallbackLng: 'nl',
    supportedLngs: ['nl'],
    detection: detectionOptions,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false
    },
    resources,
    react: {
        useSuspense: true
    },
    keySeparator: '->'
};