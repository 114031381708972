import {
    WHITELIST_PENDING,
    WHITELIST_DONE,
    WHITELIST_FETCH_DATA,
    WHITELIST_ERROR,
    WHITELIST_ERROR_CLEAR,
    WHITELIST_CLEAR_STATE_DATA
} from "../actions/whitelistOriginActions";

const initialState = {
    whitelist: {
        done: true,
        data: {
            whitelists: [],
            totalItems: 0
        },
        error: null,
    }
};

const whitelistReducer = (state = initialState, action) => {
    switch (action.type) {
        case WHITELIST_FETCH_DATA:
            return {
                ...state,
                whitelist: {
                    ...state.param,
                    data: {whitelists: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case WHITELIST_DONE:
            return {...state, whitelist: {...state.whitelist, done: true, error: null}};
        case WHITELIST_PENDING:
            return {...state, whitelist: {...state.whitelist, done: false, error: null}};
        case WHITELIST_ERROR:
            return {...state, whitelist: {...state.whitelist, done: true, error: action.payload}};
        case WHITELIST_ERROR_CLEAR:
            return {...state, whitelist: {...state.whitelist, error: null}};
        case WHITELIST_CLEAR_STATE_DATA:
            return {...state, whitelist: initialState.whitelist};
        default:
            return state;
    }
};

export default whitelistReducer;