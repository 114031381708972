import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

//assign a random id to each page size.
const pageSizeId = "vl-select-select-" + Math.floor(Math.random() * 50 + 1);

/**
 * Create a row page size option for datatable.
 * The component itself doesn't do much without Datatable component.
 */
const DatatablePageSize = ({pageSizes, defaultRowsPerPage, rowsPerPageChange}) => {
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const {t} = useTranslation('components');

    const onUserSelect = value => {
        let rowsPerPage = parseInt(value);
        setRowsPerPage(rowsPerPage);
        rowsPerPageChange(rowsPerPage);
    };

    return (
        <>
            <label htmlFor={pageSizeId} className="vl-form__label">
                {t('table->rowsPerPage')}:
            </label>
            <select
                name={pageSizeId}
                id={pageSizeId}
                className="vl-select vl-input-group vl-input-group--inline"
                tabIndex="0"
                value={rowsPerPage}
                onChange={e => onUserSelect(e.target.value)}
            >
                {
                    pageSizes.map((pageSize, index) => <option key={index} value={pageSize}>{pageSize}</option>)
                }
            </select>
        </>
    )
};

DatatablePageSize.propTypes = {
    /**
     * [Required] page sizes options to show.
     */
    pageSizes: PropTypes.array.isRequired,
    /**
     * [Required] the selected page size.
     */
    defaultRowsPerPage: PropTypes.number.isRequired,
    /**
     * [Required] callback function when rows per page change occurs.
     */
    rowsPerPageChange: PropTypes.func.isRequired,
};

DatatablePageSize.defaultProps = {
    pageSizes: [],
    defaultRowsPerPage: 0,
    rowsPerPageChange: () => null
};

export default DatatablePageSize;