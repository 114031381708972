import common from "./nl/common";
import organization from "./nl/organization";
import certificate from "./nl/certificate";
import certificateTemplates from "./nl/certificateTemplates";
import application from "./nl/application";
import domain from "./nl/domain";
import header from "./nl/header";
import components from "./nl/components.json";
import validations from "./nl/validations.json";
import valRules from "./nl/valRules.json";
import emailSetting from "./nl/emailSetting.json";
import administration from "./nl/administration.json";
import parameter from "./nl/parameter.json";
import whitelistOrigin from "./nl/whitelistOrigin.json";

export const resources = {
  nl: {
    common,
    organization,
    certificate,
    certificateTemplates,
    application,
    domain,
    header,
    components,
    validations,
    valRules,
    emailSetting,
    administration,
    parameter,
    whitelistOrigin
  },
};

export default resources;