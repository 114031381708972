import axios from "axios";
import {isEmpty} from "lodash";
import {getUserInformation, handleErrors} from "./commonActions";

export const EMA_FETCH_DATA = 'EMA_FETCH_DATA';
export const EMA_PENDING = 'EMA_PENDING';
export const EMA_DONE = 'EMA_DONE';
export const EMA_ERROR = 'EMA_ERROR';
export const EMA_ERROR_CLEAR = 'EMA_ERROR_CLEAR';
export const EMA_DYN_FETCH_DATA = 'EMA_DYN_FETCH_DATA';
export const EMA_DYN_PENDING = 'EMA_DYN_PENDING';
export const EMA_DYN_DONE = 'EMA_DYN_DONE';
export const EMA_DYN_ERROR = 'EMA_DYN_ERROR';
export const EMA_DYN_ERROR_CLEAR = 'EMA_DYN_ERROR_CLEAR';
export const EMA_CLEAR_STATE_DATA = 'EMA_CLEAR_STATE_DATA';

let apiURL = process.env.REACT_APP_API_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

/**
 * fetches all email settings
 *
 * @param {object} pagination number of emails settings shown per page
 */
export const fetchEmailSettings = (pagination = initialPagination) => async dispatch => {
    dispatch({type: EMA_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const emaResponse = await axios.post(apiURL + '/email-template/search', {
            organization_id: orgId,
            queryParameters: {
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: "ASC",
                orderBy: "name",
            },
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(emaResponse?.data.response)) {
            let userData = [], alreadyHasUserData = false;
            for (let i = 0; i < emaResponse.data.response.length; i++) {
                alreadyHasUserData = false;
                if (emaResponse.data.response[i].modified_by) {
                    userData.some(user => {
                        if (user.id === emaResponse.data.response[i].modified_by) {
                            emaResponse.data.response[i].modified_by = `${user.name} ${user.last_name}`;
                            alreadyHasUserData = true;
                            return true;
                        }
                        return false;
                    });
                    if (!alreadyHasUserData) {
                        const userInfoResponse = await dispatch(getUserInformation(orgId, emaResponse.data.response[i].modified_by));
                        emaResponse.data.response[i].modified_by = `${userInfoResponse.name} ${userInfoResponse.last_name}`;
                        userData.push(userInfoResponse);
                    }
                }
            }
            /*await Promise.all(emaResponse.data.response.map(async email => {
                if (email.modified_by) {
                    const userInfoResponse = await dispatch(getUserInformation(orgId, email.modified_by));
                    email.modified_by = `${userInfoResponse.name} ${userInfoResponse.last_name}`;
                }

                return email;
            }));*/
            dispatch({type: EMA_FETCH_DATA, payload: emaResponse.data});
        }
        dispatch({type: EMA_DONE});
    } catch (err) {
        dispatch(handleErrors(err, EMA_ERROR));
    }
};

/**
 * fetches all email settings dynamic fields
 *
 * @param {object} id id on email settings
 */
export const fetchDynamicFields = id => async dispatch => {
    dispatch({type: EMA_DYN_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const emaResponse = await axios.post(apiURL + '/email-template/dynamic-field', {
            organization_id: orgId,
            queryParameters: {
                id: id,
            },
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(emaResponse?.data.response)) {
            dispatch({type: EMA_DYN_FETCH_DATA, payload: emaResponse.data});
        }
        dispatch({type: EMA_DYN_DONE});
    } catch (err) {
        dispatch(handleErrors(err, EMA_DYN_ERROR));
    }
};

/**
 * updates the email setings with new information regarding the subject and content
 *
 * @param {object} emaId the id of email setting to update
 * @param {object} values values necessary to update the email settings
 */
export const updateEmailSettings = (emaId, values) => async dispatch => {
    dispatch({type: EMA_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/email-template/update', {
            organization_id: orgId,
            data: {
                id: emaId,
                email_content: values.email_content,
                email_subject: values.email_subject,

            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, EMA_ERROR));
    }
};

/**
 * updates the email settings active status
 *
 * @param {object} emaId the id of email setting to update
 * @param {object} status values necessary to update the email settings
 */
export const updateEmailSettingsStatus = (emaId, status) => async dispatch => {
    dispatch({type: EMA_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/email-template/update', {
            organization_id: orgId,
            data: {
                id: emaId,
                is_enabled: status,
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, EMA_ERROR));
    }
};


/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: EMA_CLEAR_STATE_DATA});
};