import React from "react";
import PropTypes from "prop-types";
import "./Datatable.scss";
import DatatableRow from "./DatatableRow";
import DatatablePagination from "./DatatablePagination";
import DatatablePageSize from "./DatatablePageSize";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";

/**
 * Use a data table to display (large quantities of) related data in a structured way.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/components/vl-ui-data-table" target="_blank">Datatable</a>
 *
 * @example ../../../styleguide/molecules/Datatable.md
 */
class Datatable extends React.Component {
    static propTypes = {
        /**
         * [Required] title object to create datatable headers.
         */
        titles: PropTypes.array.isRequired,
        /**
         * [Required] array of rows to create datatable rows.
         */
        rows: PropTypes.array.isRequired,
        /**
         * [Required] total number of rows.
         */
        totalItems: PropTypes.number.isRequired,
        /**
         * [Required] the current selected page.
         */
        currentPage: PropTypes.number.isRequired,
        /**
         * [Optional] enables the pagination functionality.
         */
        hasPagination: PropTypes.bool,
        /**
         * [Optional] enables the rows per page functionality.
         */
        hasRowsPerPage: PropTypes.bool,
        /**
         * [Required] array of page sizes.
         */
        pageSizes: PropTypes.array.isRequired,
        /**
         * [Required] the current selected rows per page.
         */
        rowsPerPage: PropTypes.number.isRequired,
        /**
         * [Optional] when want to show a loading state of datatable (useful when waiting for data to fetch).
         */
        isLoading: PropTypes.bool,
        /**
         * [Optional] function callback when page change occurs.
         */
        pageChange: PropTypes.func,
        /**
         * [Optional] function callback when rows per page change occurs.
         */
        rowsPerPageChange: PropTypes.func,
        /**
         * [Optional] function callback when mouse hover over row.
         */
        onMouseEnter: PropTypes.func,
        /**
         * [Optional] function callback when mouse hover leave the row.
         */
        onMouseLeave: PropTypes.func,
        /**
         * [Optional] function callback to sort datatable items.
         */
        requestSort: PropTypes.func,
        /**
         * [Optional] default values for sorting.
         */
        defaultSort: PropTypes.object,
        /**
         * [Optional] flag DatatablePageSize component that rows per page has changed
         */
        rowsPerPageHasChanged: PropTypes.bool
    };

    static defaultProps = {
        titles: [],
        rows: [],
        totalItems: 0,
        hasPagination: false,
        currentPage: 1,
        pageSizes: [],
        rowsPerPage: 0,
        isLoading: false,
        pageChange: () => null,
        onMouseEnter: () => null,
        onMouseLeave: () => null,
        requestSort: () => null,
        defaultSort: {key: null, direction: "ASC"},
        rowsPerPageHasChanged: false
    };

    state = {
        sortConfig: {
            key: this.props.defaultSort.key,
            direction: this.props.defaultSort.direction
        }
    };

    requestSort = key => {
        this.setState(prevState => ({
            sortConfig: {
                key: key,
                direction: prevState.sortConfig.key !== key ? 'ASC' :prevState.sortConfig.direction === 'ASC' ? 'DESC' : 'ASC'
            }
        }), () => this.props.requestSort({key, direction: this.state.sortConfig.direction}));
    };

    render() {
        const {
            titles,
            rows,
            totalItems,
            hasPagination,
            hasRowsPerPage,
            currentPage,
            pageSizes,
            rowsPerPage,
            isLoading,
            pageChange,
            rowsPerPageChange,
            onMouseEnter,
            onMouseLeave,
            rowsPerPageHasChanged
        } = this.props;
        const {sortConfig} = this.state;

        return (
            <div className={`vl-data-table-custom${isLoading ? ' is-loading' : ''}`}>
                {
                    isLoading &&
                    <div className="vl-data-table-custom__loading">
                        <div className="vl-data-table-custom__loading-message">
                            <Loader label="Data laden"/>
                        </div>
                    </div>
                }
                <table className="vl-data-table vl-data-table--hover vl-u-spacer--medium">
                    <thead>
                    <tr>
                        {titles.map((title, index) =>
                            <th key={index}>
                                {
                                    title.sortable ?
                                        <span className="vl-data-table-custom__action_hover" onClick={() => this.requestSort(title.key)}>{title.name} {(sortConfig?.key === title.key ? sortConfig.direction === 'ASC' ?
                                            <Icon name="arrow-up" iconPosition="after"/> :
                                            <Icon name="arrow-down" iconPosition="after"/> : null)}</span> : title.name
                                }
                                {
                                    title.filterable && title.filterComponent
                                }
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row, index) =>
                            <DatatableRow
                                key={index}
                                row={row}
                                titles={titles}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                            />)
                    }
                    </tbody>
                </table>
                {
                    hasPagination &&
                    <div className="vl-form-grid vl-form-grid--v-center">
                        <div className="vl-form-col--4-12">
                            {
                                hasRowsPerPage &&
                                <DatatablePageSize
                                    pageSizes={pageSizes}
                                    defaultRowsPerPage={rowsPerPage}
                                    rowsPerPageChange={rowsPerPageChange}
                                />
                            }
                        </div>
                        <div className="vl-form-col--8-12">
                            <DatatablePagination
                                totalItems={totalItems}
                                currentPage={currentPage}
                                rowsPerPage={rowsPerPage}
                                pageChange={pageChange}
                                isLoading={isLoading}
                                rowsPerPageHasChanged={rowsPerPageHasChanged}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Datatable;