import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ModalHeader from "../../components/molecules/Modal/ModalHeader";
import ModalBody from "../../components/molecules/Modal/ModalBody";
import ModalFooter from "../../components/molecules/Modal/ModalFooter";
import Modal from "../../components/molecules/Modal/Modal";

class ErrorModal extends React.Component {
    state = {
        openModal: false,
        customTitle: null,
        customMessage: null,
        customBtnLabel: null,
        message: null
    };

    componentDidUpdate(prevProps) {
        const {errorMessage} = this.props;

        if (prevProps.errorMessage !== errorMessage && errorMessage) {
            this.setState({
                customTitle:
                errorMessage?.customTitle,
                customMessage:
                errorMessage?.customMessage,
                customBtnLabel:
                errorMessage?.customBtnLabel,
                message:
                errorMessage,
                openModal: true
            });
        }
    }

    /**
     * handles the close modal action button
     * @param message
     */
    handleOnClick = message => {
        if (message.errorCode === 401) {
            sessionStorage.clear();
            window.location.reload();
        } else {
            this.setState({openModal: false});
        }
    }

    render() {
        const {t} = this.props;
        const {customTitle, customMessage, customBtnLabel, openModal, message} = this.state;

        return (
            <Modal id="error-modal" toggle={openModal} customZIndex="10010">
                <ModalHeader onModalClose={() => this.handleOnClick(message)}>
                    {(customTitle && t(`errorModal->${customTitle}`)) || t('errorModal->title')}
                </ModalHeader>
                <ModalBody>
                    {
                        customMessage ?
                            customMessage.message === "messageCAValidityDate" ?
                                <p>{t(`errorModal->${customMessage.message}`)}<a
                                    href={`mailto:${t('errorModal->messageEmailTo')}`}>{t('errorModal->messageEmailTo')}</a>.
                                </p>
                                :
                                customMessage.message === "messageInvalidSerialNumberFormat" ?
                                    <p>{t(`errorModal->${customMessage.message}`)}<a
                                        href={`mailto:${t('errorModal->messageEmailTo')}`}>{t('errorModal->messageEmailTo', {errorSubType: customMessage.additionalMessage})}</a>.
                                    </p>
                                    :
                                    <p>{t(`errorModal->${customMessage.message}`)}</p>
                            :
                            <>
                                <span className="vl-u-text--bold">{t('errorModal->description')}</span>
                                <pre>{JSON.stringify(message, 0, 2) || "Error message"}</pre>
                            </>
                    }
                </ModalBody>
                <ModalFooter
                    btnCancelHidden
                    btnLabel={customBtnLabel ? t(`errorModal->${customBtnLabel}`) : t('errorModal->btnActionLabel')}
                    onBtnActionClick={() => this.handleOnClick(customMessage || message)}
                />
            </Modal>
        );
    }
}

const translationWrapped = withTranslation('common')(ErrorModal);

const mapStateToProps = state => {
    return {
        errorMessage: state.theme.error
    };
};

export default connect(mapStateToProps, null)(translationWrapped);