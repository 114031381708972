import React from "react";
import PropTypes from "prop-types";

/**
 * Displays the content of modal body. Use it together with Modal component.
 */
export const ModalBody = ({children}) => {
    return (
        <div className="vl-modal-dialog__content vl-u-spacer--medium" id="modal-toggle-1-description">{children}</div>
    );
};

ModalBody.propTypes = {
    /**
     * [Required] display the content of modal body.
     */
    children: PropTypes.node.isRequired
};

ModalBody.defaultProps = {
    children: "Modal body"
};

export default ModalBody;